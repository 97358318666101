import FormBuilderConfig from "../../../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import IButtonBuilder from "../../../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import ISelectBuilder from "../../../../../toolkit/baseForms2/iSelectBuilder/ISelectBuilder";
import ITextBuilder from "../../../../../toolkit/baseForms2/iTextBuilder/ITextBuilder";
import { userHasPermission } from "../../../../../utils/accessTree";
import permissionCodes from "../../../../../utils/permissionCodes";

const {
  COMISIONES_V2_IMPARTICION_EVENTO_IGNORAR_PUESTO: IGNORAR_PUESTO,
  COMISIONES_V2_IMPARTICION_EVENTO_IGNORAR_CODIGO: IGNORAR_CODIGO
} = permissionCodes;

export type Inputs =
  | "ignorarCodigo"
  | "codigo"
  | "razonIgnorarCodigo"
  | "ignorarPuesto"
  | "razonIgnorarPuesto";
export type Buttons = "aceptar" | "cancelar";

export const formConfigMarcarClase: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "ignorarCodigo",
      label: "* Ignorar código del cliente",
      bts: {
        mCol: 12
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "No",
        data: [{ value: "true", label: "Si" }],
        hidden: formValue =>
          formValue.esClasePersonalizada && !userHasPermission(IGNORAR_CODIGO)
      }
    },
    {
      name: "codigo",
      label: "* Código del cliente (App)",
      bts: {
        mCol: 12
      },
      component: ITextBuilder,
      props: {
        hidden: formValue => formValue.ignorarCodigo === "true"
      }
    },
    {
      name: "razonIgnorarCodigo",
      label: "* Razón por la que se ignora el código",
      bts: {
        mCol: 12
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "Seleccione una opción",
        data: [
          {
            value: "El cliente no tiene la aplicación móvil",
            label: "El cliente no tiene la aplicación móvil"
          },
          {
            value: "El cliente no admite la (in)asistencia",
            label: "El cliente no admite la (in)asistencia"
          },
          {
            value: "El cliente no se pudo contactar",
            label: "El cliente no se pudo contactar"
          },
          {
            value: "No aplica (club no implementado)",
            label: "No aplica (club no implementado)"
          }
        ],
        hidden: formValue => formValue.ignorarCodigo !== "true"
      }
    },
    {
      name: "ignorarPuesto",
      label: "Ignorar puesto de trabajo",
      bts: {
        mCol: 12
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "No",
        data: [{ value: "true", label: "Si" }],
        hidden: () => !userHasPermission(IGNORAR_PUESTO)
      }
    },
    {
      name: "razonIgnorarPuesto",
      label: "Razón",
      bts: {
        mCol: 12
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "Seleccione una opción",
        data: [
          {
            value: "Personal no cubierto en el puesto",
            label: "Personal no cubierto en el puesto"
          },
          {
            value: "Baja inesperada de colaborador(a)",
            label: "Baja inesperada de colaborador(a)"
          },
          {
            value: "Excepción aceptada por T. y C.",
            label: "Excepción aceptada por T. y C."
          }
        ],
        hidden: () => !userHasPermission(IGNORAR_PUESTO),
        disabled: formValue => formValue.ignorarPuesto !== "true"
      }
    }
  ],
  buttons: [
    {
      name: "aceptar",
      label: "Aceptar",
      isSubmit: true,
      component: IButtonBuilder,
      bts: {
        mOff: 4,
        mCol: 4
      }
    },
    {
      name: "cancelar",
      label: "Cancelar",
      component: IButtonBuilder,
      bts: {
        mCol: 4
      }
    }
  ],
  validations: {
    codigo: {
      requiredIfNotIgnorarCodigo: {
        validator: (value, formValue) =>
          formValue.ignorarCodigo !== "true" && !value,
        message: () => "Debe proporcionarlo el cliente"
      }
    },
    razonIgnorarCodigo: {
      requiredIfIgnorarCodigo: {
        validator: (value, formValue) =>
          formValue.ignorarCodigo === "true" && !value,
        message: () => "Campo obligatorio"
      }
    },
    razonIgnorarPuesto: {
      requiredIfIgnorarPuesto: {
        validator: (value, formValue) =>
          formValue.ignorarPuesto === "true" && !value,
        message: () => "Campo obligatorio"
      }
    }
  },
  rows: [
    ["ignorarCodigo", "codigo", "razonIgnorarCodigo"],
    ["ignorarPuesto"],
    ["razonIgnorarPuesto"],
    ["aceptar", "cancelar"]
  ]
};
