import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";
import FileDownload from "js-file-download";
import { errorNotification } from "../../utils/notifications";
import moment from "moment";

const REPORTS_BASE_URL = "/api/reports";
const PERIODS_BASE_URL = `/api/periods`;
const SELLS_BASE_URL = "/api/sales";
const PARTNERS_BASE_URL = "/api/partners";

export const getComissionsReport = (fields: object): IAction => {
  // @ts-ignore
  if (fields.tipoComision == -1) {
    // @ts-ignore
    fields.tipoComision = null;
  }

  // @ts-ignore
  if (fields.estatusComision == -1) {
    // @ts-ignore
    fields.estatusComision = null;
  }
  return {
    type: actionTypes.GET_REPORT_COMISSION,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${REPORTS_BASE_URL}/comissions`,
        fields,
        {
          withCredentials: true
        }
      )
    }
  };
};

// Reporte de comisiones de venta
export const getComissionsSaleReport = (filter: any): IAction => {
  const requestQuery = Object.keys(filter)
    .map(key =>
      filter[key] != null
        ? encodeURIComponent(key) + "=" + encodeURIComponent(filter[key])
        : null
    )
    .filter(str => str != null)
    .join("&");

  return {
    type: actionTypes.GET_REPORT_COMISSION_SALE,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${REPORTS_BASE_URL}/comissions/sale?${requestQuery}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const downloadMembershipsSalesComissionsReport = (
  fields: object
): IAction => {
  // @ts-ignore
  if (fields.tipoComision == -1) {
    // @ts-ignore
    fields.tipoComision = null;
  }

  // @ts-ignore
  if (fields.estatusComision == -1) {
    // @ts-ignore
    fields.estatusComision = null;
  }

  return {
    type: actionTypes.GET_MEMB_SALES_COMISSIONS_REPORT_FILE,
    payload: {
      promise: axios
        .post(
          `${constants.BASE_URL}${REPORTS_BASE_URL}/comissions/download`,
          fields,
          {
            withCredentials: true,
            responseType: "blob"
          }
        )
        .then(({ data }) => {
          FileDownload(data, "ReporteComisionesVentaMembresias.csv");
        })
    }
  };
};

export const getReactivationComissionsReport = (fields: object): IAction => {
  // @ts-ignore
  if (fields.estatusComision == -1) {
    // @ts-ignore
    fields.estatusComision = null;
  }

  return {
    type: actionTypes.GET_REPORT_REACTIVATION_COMISSION,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${REPORTS_BASE_URL}/comissions/reactivation`,
        fields,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const downloadMembershipsReactivationsComissionsReport = (
  fields: object
): IAction => {
  // @ts-ignore
  if (fields.estatusComision == -1) {
    // @ts-ignore
    fields.estatusComision = null;
  }

  return {
    type: actionTypes.GET_MEMB_REACT_COMISSIONS_REPORT_FILE,
    payload: {
      promise: axios
        .post(
          `${constants.BASE_URL}${REPORTS_BASE_URL}/comissions/reactivation/download`,
          fields,
          {
            withCredentials: true,
            responseType: "blob"
          }
        )
        .then(({ data }) => {
          FileDownload(data, "ReporteComisionesReactivacionMembresias.csv");
        })
    }
  };
};

export const clearReactivationComissionsReport = (): IAction => {
  return {
    type: actionTypes.CLEAR_REPORT_REACTIVATION_COMISSION,
    payload: null
  };
};

export const getPastPeriods = (limite, cantidad): IAction => {
  return {
    type: actionTypes.GET_PERIODS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${PERIODS_BASE_URL}/pastPeriods/${limite}/${cantidad}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getPastPeriodsRequest = (limite, cantidad) => {
  return axios.get(
    `${constants.BASE_URL}${PERIODS_BASE_URL}/pastPeriods/${limite}/${cantidad}`,
    {
      withCredentials: true
    }
  );
};

export const getPastMonthPeriods = (): IAction => {
  return {
    type: actionTypes.GET_MONTH_PERIODS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${PERIODS_BASE_URL}/pastMonthPeriods`,
        {
          withCredentials: true
        }
      )
    }
  };
};
export const getPastMonthPeriodsRequest = () => {
  return axios.get(
    `${constants.BASE_URL}${PERIODS_BASE_URL}/pastMonthPeriods`,
    {
      withCredentials: true
    }
  );
};

export const getMembershipSoldReport = (fields: object): IAction => {
  return {
    type: actionTypes.GET_REPORT_MEMBERSHIP_SOLD,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${REPORTS_BASE_URL}/membershipSoldReport`,
        fields,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const sendReasignMembership = (fields: object): IAction => {
  return {
    type: actionTypes.REASIGN_MEMBERSHIP,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${REPORTS_BASE_URL}/reasignMembership`,
        fields,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const clearReasignMembership = (): IAction => {
  return {
    type: actionTypes.CLEAR_REASIGN_MEMBERSHIP,
    payload: null
  };
};

export const sendSells = (
  tiposPago: any[],
  cantidad: number,
  nroItems: number
): IAction => {
  const fields = {
    tiposPago,
    cantidad,
    nroItems
  };
  return {
    type: actionTypes.SEND_LTE_SELLS,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${SELLS_BASE_URL}/generar`,
        fields,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const clearDatasendSells = (): IAction => {
  return {
    type: actionTypes.SEND_LTE_SELLS_CLEAR,
    payload: null
  };
};

export const getComissionsProduct = (fields: object): IAction => {
  // @ts-ignore
  if (fields.estatus == -1) {
    // @ts-ignore
    fields.estatus = null;
  }
  return {
    type: actionTypes.GET_REPORT_COMISSION,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${REPORTS_BASE_URL}/comissionsProducts`,
        fields,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const downloadComissionsProductReport = (fields: object): IAction => {
  // @ts-ignore
  if (fields.estatus == -1) {
    // @ts-ignore
    fields.estatus = null;
  }
  return {
    type: actionTypes.DOWNLOAD_REPORT_COMISSION,
    payload: {
      promise: axios
        .get(
          `${constants.BASE_URL}${REPORTS_BASE_URL}/comissionsProducts/download`,
          {
            withCredentials: true,
            params: {
              ...fields
            },
            responseType: "blob"
          }
        )
        .then(({ data }) => {
          FileDownload(data, "ReporteComisionesProductosDeportiva.csv");
        })
    }
  };
};

export const authorizeComissions = (comissionsList: object): IAction => {
  return {
    type: actionTypes.AUTHORIZE_COMISSION,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${REPORTS_BASE_URL}/authorize`,
        comissionsList,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const authorizeMemberships = (membershipsList: object): IAction => {
  return {
    type: actionTypes.AUTHORIZE_COMISSION,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${REPORTS_BASE_URL}/membership-authorize`,
        membershipsList,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getSportsProgramCommissions = (id: string): IAction => {
  return {
    type: actionTypes.GET_REPORT_SPORTS_PROGRAM_COMMISSIONS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${REPORTS_BASE_URL}/sports-program-commissions/${id}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getDepositoReferenciado = (
  clubId: string,
  fechaDePago: string
): IAction => {
  return {
    type: actionTypes.GET_DEPOSITO_REFERENCIADO,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${REPORTS_BASE_URL}/deposito-referenciado/${clubId}/${fechaDePago}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const downloadSportsProgramCommissions = (args): IAction => {
  return {
    type: actionTypes.GET_DEMANDS_FILE,
    payload: {
      promise: axios
        .get(
          `${constants.BASE_URL}${REPORTS_BASE_URL}/sports-program-commissions/download/${args.programaId}/${args.clubId}/${args.instructorId}/${args.fechaInicio}/${args.fechaFin}`,
          {
            withCredentials: true,
            responseType: "blob"
          }
        )
        .then(({ data }) => {
          FileDownload(data, "comisiones.csv");
        })
    }
  };
};

export const searchReportAccountingAccount = (body: any): IAction => {
  return {
    type: actionTypes.GET_REPORT_ACCOUNTING_ACCOUNT,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${REPORTS_BASE_URL}/report-acounting-acount`,
        {
          withCredentials: true,
          params: { ...body }
        }
      )
    }
  };
};

export const downloadReportAccountingAccount = (
  body: any,
  callback?: any
): IAction => {
  return {
    type: actionTypes.GET_FILE_ACCOUNTING_ACCOUNT,
    payload: {
      promise: axios
        .get(
          `${constants.BASE_URL}${REPORTS_BASE_URL}/report-acounting-acount/download`,
          {
            withCredentials: true,
            params: { ...body },
            responseType: "blob"
          }
        )
        .then(({ data }) => {
          FileDownload(data, "CUENTA-CONTABLE.csv");
          callback && callback();
        })
        .catch(err => {
          callback && callback();
          const msg = "No se pudo descargar el archivo";
          if (err && err.response && err.response.data) {
            err.response.data.text().then(t => {
              try {
                const res = JSON.parse(t);
                const message = res.message || JSON.parse(res).message || msg;
                errorNotification(message);
              } catch (erj) {
                errorNotification(msg);
              }
            });
          } else {
            errorNotification(msg);
          }
        })
    }
  };
};

export const downloadReportAccountingAccountFileId = filters => {
  const url = `${constants.BASE_URL}${REPORTS_BASE_URL}/report-acounting-acount/download`;
  const config = {
    withCredentials: true,
    params: { ...filters }
  };
  return axios.get(url, config);
};

export const searchReportWayToPay = (body: any): IAction => {
  return {
    type: actionTypes.GET_REPORT_WAY_TO_PAY,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${REPORTS_BASE_URL}/report-way-to-pay`,
        {
          withCredentials: true,
          params: { ...body }
        }
      )
    }
  };
};

export const downloadReportWayToPay = (body: any, callback?: any): IAction => {
  return {
    type: actionTypes.GET_FILE_WAY_TO_PAY,
    payload: {
      promise: axios
        .get(
          `${constants.BASE_URL}${REPORTS_BASE_URL}/report-way-to-pay/download`,
          {
            withCredentials: true,
            params: { ...body },
            responseType: "blob"
          }
        )
        .then(({ data }) => {
          FileDownload(data, "FORMA-DE-PAGO.csv");
          callback && callback();
        })
        .catch(err => {
          callback && callback();
          const msg = "No se pudo descargar el archivo";

          if (err && err.response && err.response.data) {
            err.response.data.text().then(t => {
              try {
                const res = JSON.parse(t);
                const message = res.message || JSON.parse(res).message || msg;
                errorNotification(message);
              } catch (erj) {
                errorNotification(msg);
              }
            });
          } else {
            errorNotification(msg);
          }
        })
    }
  };
};

export const downloadReportWayToPayFileId = filters => {
  const url = `${constants.BASE_URL}${REPORTS_BASE_URL}/report-way-to-pay/download`;
  const config = {
    withCredentials: true,
    params: { ...filters }
  };
  return axios.get(url, config);
};

export const getIngressByClubReport = (params: any): IAction => {
  return {
    type: actionTypes.GET_INGRESS_BY_CLUB_REPORT,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${REPORTS_BASE_URL}/ingress-by-club`,
        {
          withCredentials: true,
          params
        }
      )
    }
  };
};

export const searchReportGeneralCobranza = (body: any) => {
  return axios.get(
    `${constants.BASE_URL}${REPORTS_BASE_URL}/report-general-cobranza`,
    {
      withCredentials: true,
      params: { ...body }
    }
  );
};

export const downloadReportGeneralCobranzaFileId = filters => {
  const url = `${constants.BASE_URL}${REPORTS_BASE_URL}/report-general-cobranza/download`;
  const config = {
    withCredentials: true,
    params: { ...filters }
  };
  return axios.get(url, config);
};

export const downloadIngressByClubReport = (
  idClub,
  fechaInicio,
  fechaFin
): IAction => {
  return {
    type: actionTypes.DOWNLOAD_INGRESS_BY_CLUB_REPORT,
    payload: {
      promise: axios
        .get(
          `${constants.BASE_URL}${REPORTS_BASE_URL}/ingress-by-club/download`,
          {
            withCredentials: true,
            params: {
              idClub,
              fechaInicio,
              fechaFin
            },
            responseType: "blob"
          }
        )
        .then(({ data }) => {
          FileDownload(data, "ReporteAccesoAClubes.csv");
        })
    }
  };
};

export const getTotalSalesReport = (body: any): IAction => {
  return {
    type: actionTypes.GET_TOTAL_SALES_REPORT,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${REPORTS_BASE_URL}/total-sales-report`,
        {
          withCredentials: true,
          params: { ...body }
        }
      )
    }
  };
};

export const getAmountSalesReport = (body: any): IAction => {
  return {
    type: actionTypes.GET_AMOUNT_SALES_REPORT,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${REPORTS_BASE_URL}/amount-sales-report`,
        {
          withCredentials: true,
          params: { ...body }
        }
      )
    }
  };
};

export const getComissionsSummaryReportRequest = (
  sellerId: number,
  periodId: number,
  status: string
) => {
  return axios.get(
    `${constants.BASE_URL}${REPORTS_BASE_URL}/comissions-by-seller`,
    {
      withCredentials: true,
      params: {
        periodoId: periodId,
        estatus: status
      }
    }
  );
};

export const downloadComissionsSummaryReport = (filters, _callback) => {
  return axios
    .get(
      `${constants.BASE_URL}${REPORTS_BASE_URL}/comissions-by-seller/download`,
      {
        withCredentials: true,
        params: filters,
        responseType: "blob"
      }
    )
    .then(({ data }) => {
      if (!!_callback) {
        _callback();
      }
      FileDownload(data, "ReportComisionesPorVendedor.csv");
    });
};

export const getCashCut = (clubId: string, fechaDePago: string): IAction => {
  return {
    type: actionTypes.GET_CASH_CUT,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}/api/maintenances/corte-caja/${clubId}/${fechaDePago}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const clearCashCut = (): IAction => {
  return {
    type: actionTypes.CLEAR_CASH_CUT,
    payload: null
  };
};

export const pdfCashCut = (
  data: object,
  clubId: string,
  fechaDePago: string
): IAction => {
  return {
    type: actionTypes.PDF_CASH_CUT,
    payload: {
      promise: axios
        .post(
          `${constants.BASE_URL}/api/maintenances/corte-caja/pdf/${clubId}/${fechaDePago}`,
          data,
          {
            withCredentials: true,
            responseType: "blob"
          }
        )
        .then(({ data }) => {
          FileDownload(data, "reporte-corte-de-caja.pdf");
        })
    }
  };
};

export const getShiftDelivery = (
  clubId: string,
  fechaDePago: string
): IAction => {
  return {
    type: actionTypes.GET_SHIFT_DELIVERY,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}/api/maintenances/entrega-turno/${clubId}/${fechaDePago}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const pdfShiftDelivery = (
  data: object,
  clubId: string,
  fechaDePago: string
): IAction => {
  return {
    type: actionTypes.PDF_SHIFT_DELIVERY,
    payload: {
      promise: axios
        .post(
          `${constants.BASE_URL}/api/maintenances/entrega-turno/pdf/${clubId}/${fechaDePago}`,
          data,
          {
            withCredentials: true,
            responseType: "blob"
          }
        )
        .then(({ data }) => {
          FileDownload(data, "reporte-entrega-turno.pdf");
        })
    }
  };
};

export const pdfReferencedDeposit = (
  clubId: string,
  fechaDePago: string
): IAction => {
  return {
    type: actionTypes.PDF_REFERENCED_DEPOSIT,
    payload: {
      promise: axios
        .post(
          `${constants.BASE_URL}/api/maintenances/deposito-referenciado/pdf/${clubId}/${fechaDePago}`,
          {},
          {
            withCredentials: true,
            responseType: "blob"
          }
        )
        .then(({ data }) => {
          FileDownload(data, "deposito-referenciado.pdf");
        })
    }
  };
};

export const getCreditNotes = (
  clubId: string,
  fechaInicio: string,
  fechaFin: string,
  fechaInicioAutorizacion: string,
  fechaFinAutorizacion: string,
  estatus: string,
  tipo: string
): IAction => {
  return {
    type: actionTypes.GET_CREDIT_NOTES,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}/api/maintenances/notas-credito/reporte`,
        {
          withCredentials: true,
          params: {
            clubId,
            fechaInicio,
            fechaFin,
            fechaInicioAutorizacion,
            fechaFinAutorizacion,
            estatus,
            tipo
          }
        }
      )
    }
  };
};

export const downloadCreditNotesReport = (
  clubId: string,
  fechaInicio: string,
  fechaFin: string,
  fechaInicioAutorizacion: string,
  fechaFinAutorizacion: string,
  estatus: string,
  tipo: string
): IAction => {
  return {
    type: actionTypes.GET_CREDIT_NOTE_REPORT_FILE,
    payload: {
      promise: axios
        .get(
          `${constants.BASE_URL}/api/maintenances/notas-credito/reporte/descargar`,
          {
            withCredentials: true,
            params: {
              clubId,
              fechaInicio,
              fechaFin,
              fechaInicioAutorizacion,
              fechaFinAutorizacion,
              estatus,
              tipo
            },
            responseType: "blob"
          }
        )
        .then(({ data }) => {
          FileDownload(data, "ReportSolicitudesNotaCredito.csv");
        })
    }
  };
};

export const getSalesPayable = (
  page: string,
  size: string,
  fechaDesde: string,
  fechaHasta: string,
  folio: string
): IAction => {
  return {
    type: actionTypes.GET_SALES_PAYABLE,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}/api/maintenances/venta/por-pagar`,
        {
          withCredentials: true,
          params: { page, size, fechaDesde, fechaHasta, folio }
        }
      )
    }
  };
};

export const getSalesPayableRequest = (
  page: string,
  size: string,
  fechaDesde: string,
  fechaHasta: string,
  folio: string
) => {
  return axios.get(`${constants.BASE_URL}/api/maintenances/venta/por-pagar`, {
    withCredentials: true,
    params: { page, size, fechaDesde, fechaHasta, folio }
  });
};

export const processCreditNote = (solicitudId: string): IAction => {
  return {
    type: actionTypes.PROCESS_CREDIT_NOTE,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}/api/maintenances/nota-credito/${solicitudId}`,
        {},
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getCreditNoteReqByIdRequest = (solicitudId: string) => {
  return axios.get(
    `${constants.BASE_URL}/api/maintenances/nota-credito/${solicitudId}`,
    {
      withCredentials: true
    }
  );
};

export const getCreditNoteReqItemsDevolucionById = (solicitudId: string) => {
  return axios.get(
    `${constants.BASE_URL}/api/maintenances/nota-credito/${solicitudId}/items-devolucion`,
    {
      withCredentials: true
    }
  );
};

export const postVerificarCancelacion = (uuid: string) => {
  return axios.post(
    `${constants.BASE_URL}/api/maintenances/nota-credito/verificar-cancelacion/${uuid}`,
    {},
    {
      withCredentials: true
    }
  );
};

export const postConfirmarCancelacion = (uuid: string) => {
  return axios.post(
    `${constants.BASE_URL}/api/maintenances/nota-credito/confirmar-cancelacion/${uuid}`,
    {},
    {
      withCredentials: true
    }
  );
};

export const postRefacturar = (id: string) => {
  return axios.post(
    `${constants.BASE_URL}/api/maintenances/nota-credito/refacturar/${id}`,
    {},
    {
      withCredentials: true
    }
  );
};

export const rejectCreditNoteDemand = (
  notaCreditoSolicitudId: string
): IAction => {
  return {
    type: actionTypes.REJECT_CREDIT_NOTE_DEMAND,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}/api/maintenances/nota-credito/rechazar/${notaCreditoSolicitudId}`,
        {},
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getCustomizableReceipt = (
  clubId: string,
  fechaInicio: string,
  fechaFin: string,
  estatus: string
): IAction => {
  return {
    type: actionTypes.GET_CUSTOMIZABLE_RECEIPT,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}/api/maintenances/recibo-provicional`,
        {
          withCredentials: true,
          params: { clubId, fechaInicio, fechaFin, estatus }
        }
      )
    }
  };
};

export const downloadCustomizableReceipt = (body: any): IAction => {
  return {
    type: actionTypes.GET_FILE_ACCOUNTING_ACCOUNT,
    payload: {
      promise: axios
        .get(`${constants.BASE_URL}/api/maintenances/recibo-provicional/csv`, {
          withCredentials: true,
          params: { ...body },
          responseType: "blob"
        })
        .then(({ data }) => {
          FileDownload(data, "RECIBO-PROVICIONAL.csv");
        })
    }
  };
};

export const refuseCustomizableReceipt = (
  reciboProvicionalId: string
): IAction => {
  return {
    type: actionTypes.REFUSE_CUSTOMIZABLE_RECEIPT,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}/api/maintenances/recibo-provicional/rechazar/${reciboProvicionalId}`,
        {},
        {
          withCredentials: true
        }
      )
    }
  };
};

export const validateCustomizableReceipt = (
  reciboProvicionalId: string
): IAction => {
  return {
    type: actionTypes.VALIDATE_CUSTOMIZABLE_RECEIPT,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}/api/maintenances/recibo-provicional/validar/${reciboProvicionalId}`,
        {},
        {
          withCredentials: true
        }
      )
    }
  };
};

export const historicalRebilling = (ventaId: string): IAction => {
  return {
    type: actionTypes.HISTORICAL_REBILLING,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}/api/maintenances/refacturacion-historico/${ventaId}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const pdfProvitionalReceipt = (reciboProvisionalId: string): IAction => {
  return {
    type: actionTypes.PDF_PROVITIONAL_RECEIPT,
    payload: {
      promise: axios
        .post(
          `${constants.BASE_URL}/api/maintenances/recibo/provisional/${reciboProvisionalId}/pdf`,
          {},
          {
            withCredentials: true,
            responseType: "blob"
          }
        )
        .then(({ data }) => {
          FileDownload(data, "reporte-recibo-provicional.pdf");
        })
    }
  };
};

export const getSantanderReference = (
  clubId: string,
  formaDePago: string,
  fechaInicio: string,
  fechaFin: string,
  page: string,
  size: string
): IAction => {
  return {
    type: actionTypes.GET_REFERENCE_SANTANDER_REPORT,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}/api/maintenances/referencia/santander/${clubId}/${formaDePago}/${fechaInicio}/${fechaFin}/${page}/${size}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getReferenceConsecutiveClub = (clubId: string): IAction => {
  return {
    type: actionTypes.GET_REFERENCE_CONSECUTIVE_CLUB,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}/api/maintenances/referencia/santander/consecutivo-dia/${clubId}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const createReferencesByClub = (
  clubId: string,
  monto: string,
  formaDePago: string,
  fechaCreacion: string,
  numeroReferencias: string
): IAction => {
  return {
    type: actionTypes.CREATE_REFERENCES_BY_CLUB,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}/api/maintenances/referencia/santander/complemento-pago/${clubId}/${monto}/${formaDePago}/${fechaCreacion}/${numeroReferencias}`,
        {},
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getSantanderReferenceCsv = (body: object): IAction => {
  return {
    type: actionTypes.GET_SANTANDER_REFERENCE_CSV,
    payload: {
      promise: axios
        .get(
          `${constants.BASE_URL}/api/maintenances/referencia/santander/csv`,
          {
            withCredentials: true,
            params: { ...body },
            responseType: "blob"
          }
        )
        .then(({ data }) => {
          FileDownload(data, "REFERENCIAS.csv");
        })
    }
  };
};

export const getRefunds = (
  clubId: string,
  fechaInicio: string,
  fechaFin: string,
  page: string,
  size: string
): IAction => {
  return {
    type: actionTypes.GET_REFUNDS_REPORT,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}/api/maintenances/devoluciones/${clubId}/${fechaInicio}/${fechaFin}/${page}/${size}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getRefundsCsv = (body: object): IAction => {
  return {
    type: actionTypes.GET_REFUNDS_CSV,
    payload: {
      promise: axios
        .get(`${constants.BASE_URL}/api/maintenances/devoluciones/csv`, {
          withCredentials: true,
          params: { ...body },
          responseType: "blob"
        })
        .then(({ data }) => {
          FileDownload(data, "REFERENCIAS APLICADAS.csv");
        })
    }
  };
};

export const authorizeRefund = (solicitudId: string): IAction => {
  return {
    type: actionTypes.AUTHORIZE_REFOUND,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}/api/maintenances/devoluciones/autorizar/${solicitudId}`,
        {},
        {
          withCredentials: true
        }
      )
    }
  };
};

export const refuseRefund = (solicitudId: string): IAction => {
  return {
    type: actionTypes.REFUSE_REFOUND,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}/api/maintenances/devoluciones/rechazar/${solicitudId}`,
        {},
        {
          withCredentials: true
        }
      )
    }
  };
};

export const chargebackRefund = (solicitudId: string): IAction => {
  return {
    type: actionTypes.CHARGEBACK_REFOUND,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}/api/maintenances/devoluciones/contracargo/${solicitudId}`,
        {},
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getSantanderRefDosntApply = (
  clubId: string,
  formaDePago: string,
  fechaInicio: string,
  fechaFin: string,
  page: string,
  size: string
): IAction => {
  return {
    type: actionTypes.GET_REFERENCE_SANTANDER_REPORT_DA,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}/api/maintenances/referencia/no-aplicada/santander/${clubId}/${formaDePago}/${fechaInicio}/${fechaFin}/${page}/${size}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getSantanderRefDosntApplyCsv = (body: object): IAction => {
  return {
    type: actionTypes.GET_SANTANDER_REFERENCE_CSV_DA,
    payload: {
      promise: axios
        .get(
          `${constants.BASE_URL}/api/maintenances/referencia/no-aplicada/santander/csv`,
          {
            withCredentials: true,
            params: { ...body },
            responseType: "blob"
          }
        )
        .then(({ data }) => {
          FileDownload(data, "REFERENCIAS NO APLICADAS.csv");
        })
    }
  };
};

export const getEmployees = (
  clubId: string,
  email: string,
  empresaId: string,
  estatus: string,
  estatusEvaluacion: string,
  nombre: string,
  numero: string,
  puesto: string,
  sinEmail: string,
  pageNumber: string,
  pageSize: string
): IAction => {
  return {
    type: actionTypes.GET_REPORT_EMPLOYEES,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}/api/reports/employees-report/${clubId}/${email}/${empresaId}/${estatus}/${estatusEvaluacion}/${nombre}/${numero}/${puesto}/${sinEmail}/${pageNumber}/${pageSize}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getEmployeesCsv = (body: object): IAction => {
  return {
    type: actionTypes.GET_REPORT_EMPLOYEES_CSV,
    payload: {
      promise: axios
        .get(`${constants.BASE_URL}/api/reports/employees-report/csv`, {
          withCredentials: true,
          params: { ...body },
          responseType: "blob"
        })
        .then(({ data }) => {
          FileDownload(data, "EMPLEADOS.csv");
        })
    }
  };
};

export const getReportMonthlyAccrualRequest = (
  clubVentaId: number,
  clubDispersionId: number,
  fechaInicio: string,
  fechaFin: string,
  tipo: string,
  tipoProducto: string
) => {
  return axios.get(
    `${constants.BASE_URL}${REPORTS_BASE_URL}/report-monthly-accrual`,
    {
      withCredentials: true,
      params: {
        clubVentaId,
        clubDispersionId,
        fechaInicio,
        fechaFin,
        tipo,
        tipoProducto
      }
    }
  );
};

export const downloadReportMonthlyAccrual = (
  clubVentaId: number,
  clubDispersionId: number,
  fechaInicio: string,
  fechaFin: string,
  tipo: string,
  tipoProducto: string
): IAction => {
  return {
    type: actionTypes.GET_FILE_MONTHLY_ACCRUAL,
    payload: {
      promise: axios
        .get(
          `${constants.BASE_URL}${REPORTS_BASE_URL}/report-monthly-accrual/download`,
          {
            withCredentials: true,
            params: {
              clubVentaId,
              clubDispersionId,
              fechaInicio,
              fechaFin,
              tipo,
              tipoProducto
            },
            responseType: "blob"
          }
        )
        .then(({ data }) => {
          FileDownload(
            data,
            `ReporteDevengamientoMensual_${fechaInicio || ""}_${fechaFin ||
              ""}.csv`
          );
        })
    }
  };
};

export const getReportItemsToAccrualBySaleDate = (fecha: string) => {
  const url = `${constants.BASE_URL}${REPORTS_BASE_URL}/report-items-to-accrual-by-sale-date`;
  const config = { withCredentials: true, params: { fecha } };
  return axios.get(url, config);
};

export const downloadReportItemsToAccrualBySaleDate = (
  fecha: string
): IAction => {
  const url = `${constants.BASE_URL}${REPORTS_BASE_URL}/report-items-to-accrual-by-sale-date/download`;
  const config = {
    withCredentials: true,
    params: { fecha },
    responseType: "blob"
  };

  return {
    type: actionTypes.GET_FILE_ITEMS_TO_ACCRUAL_BY_SALE_DATE,
    payload: {
      promise: axios.get(url, config).then(({ data }) => {
        FileDownload(data, `ReporteItemsPorDevengarPorFechaVenta.csv`);
      })
    }
  };
};

export const getReportCatPreliminar = (clubId: number) => {
  const url = `${constants.BASE_URL}${REPORTS_BASE_URL}/report-cat-preliminar`;
  const config = { withCredentials: true, params: { clubId } };
  return axios.get(url, config);
};

export const downloadReportCatPreliminar = (clubId: number): IAction => {
  return {
    type: actionTypes.GET_FILE_CAT_PRELIMINAR,
    payload: {
      promise: axios
        .get(
          `${constants.BASE_URL}${REPORTS_BASE_URL}/report-cat-preliminar/download`,
          { withCredentials: true, params: { clubId }, responseType: "blob" }
        )
        .then(({ data }) => {
          FileDownload(data, `ReporteCatPreliminar.csv`);
        })
    }
  };
};

export const getDebtRestructuringCatPreliminar = (
  clubId: number,
  diasACobrar
) => {
  const url = `${constants.BASE_URL}${REPORTS_BASE_URL}/debt-restructuring-cat-preliminar`;
  const config = { withCredentials: true, params: { clubId, diasACobrar } };
  return axios.get(url, config);
};

export const authorizeDebtRestructuringCatPreliminar = (
  clubId: number,
  diasACobrar
) => {
  const url = `${constants.BASE_URL}${REPORTS_BASE_URL}/debt-restructuring-cat-preliminar/authorize?clubId=${clubId}&diasACobrar=${diasACobrar}`;
  const config = { withCredentials: true };
  return axios.post(url, {}, config);
};

export const downloadDebtRestructuringCatPreliminar = (
  clubId: number,
  diasACobrar
): IAction => {
  return {
    type: actionTypes.GET_FILE_CAT_PRELIMINAR,
    payload: {
      promise: axios
        .get(
          `${constants.BASE_URL}${REPORTS_BASE_URL}/debt-restructuring-cat-preliminar/download`,
          {
            withCredentials: true,
            params: { clubId, diasACobrar },
            responseType: "blob"
          }
        )
        .then(({ data }) => {
          FileDownload(data, `ReporteRestructuracionDeudaCatPreliminar.csv`);
        })
    }
  };
};

export const getReportAccrualProcesses = (clubId: number, fecha) => {
  const url = `${constants.BASE_URL}${REPORTS_BASE_URL}/accrual/processes`;
  const config = { withCredentials: true, params: { clubId, fecha } };
  return axios.get(url, config);
};

export const getReportAccrualProcessLinesSent = (procesoId: number) => {
  const url = `${constants.BASE_URL}${REPORTS_BASE_URL}/accrual/process/lines`;
  const config = { withCredentials: true, params: { procesoId } };
  return axios.get(url, config);
};

export const downloadReportAccrualProcessLinesSent = (
  procesoId: number
): IAction => {
  return {
    type: actionTypes.GET_FILE_CAT_PRELIMINAR,
    payload: {
      promise: axios
        .get(
          `${constants.BASE_URL}${REPORTS_BASE_URL}/accrual/process/lines/download`,
          {
            withCredentials: true,
            params: { procesoId },
            responseType: "blob"
          }
        )
        .then(({ data }) => {
          FileDownload(data, `ReporteProcesoDevengamientoLineas.csv`);
        })
    }
  };
};

export const getReportCatProcessesDirectionSummary = () => {
  const url = `${constants.BASE_URL}${REPORTS_BASE_URL}/report-cat-processes-summary-direction`;
  const config = { withCredentials: true, params: {} };
  return axios.get(url, config);
};

export const downloadReportCatProcessesDirectionSummary = (): IAction => {
  return {
    type: actionTypes.GET_FILE_CAT_PRELIMINAR,
    payload: {
      promise: axios
        .get(
          `${constants.BASE_URL}${REPORTS_BASE_URL}/report-cat-processes-summary-direction/download`,
          { withCredentials: true, params: {}, responseType: "blob" }
        )
        .then(({ data }) => {
          FileDownload(data, `ReporteResumenDireccionProcesosCat.csv`);
        })
    }
  };
};

export const downloadReportCatPaymentLines = (reportDate: String): IAction => {
  return {
    type: actionTypes.GET_FILE_CAT_PRELIMINAR,
    payload: {
      promise: axios
        .get(
          `${constants.BASE_URL}${REPORTS_BASE_URL}/report-cat-payment-lines/download`,
          {
            withCredentials: true,
            params: { fecha: reportDate },
            responseType: "blob"
          }
        )
        .then(({ data }) => {
          FileDownload(data, `ReporteCatLineasPago.csv`);
        })
    }
  };
};

export const getReportSalesNotBilledRequest = (
  clubVentaId: string,
  fechaInicio: string,
  fechaFin: string
) => {
  return axios.get(
    `${constants.BASE_URL}${REPORTS_BASE_URL}/report-sales-not-billed`,
    {
      withCredentials: true,
      params: { clubVentaId, fechaInicio, fechaFin }
    }
  );
};

export const downloadReportSalesNotBilled = (
  clubVentaId: number,
  fechaInicio: string,
  fechaFin: string
): IAction => {
  return {
    type: actionTypes.GET_FILE_MONTHLY_ACCRUAL,
    payload: {
      promise: axios
        .get(
          `${constants.BASE_URL}${REPORTS_BASE_URL}/report-sales-not-billed/download`,
          {
            withCredentials: true,
            params: { clubVentaId, fechaInicio, fechaFin },
            responseType: "blob"
          }
        )
        .then(({ data }) => {
          FileDownload(
            data,
            `ReporteVentasNoFacturadas_${fechaInicio || ""}_${fechaFin ||
              ""}.csv`
          );
        })
    }
  };
};

export const getMaintenancesPriceGrid = (clubId: string, fecha: number) => {
  return axios.get(`${constants.BASE_URL}/api/maintenances/prices-grid`, {
    withCredentials: true,
    params: { clubId, fecha }
  });
};

export const printMaintenancesPricesGrid = (fecha, clubId): void => {
  window.open(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/prices-grid/render?fecha=${fecha}&clubId=${clubId}`,
    "_blank"
  );
};

export const downloadMaintenancesPriceGrid = (
  clubId: number,
  anio: number
): IAction => {
  return {
    type: actionTypes.GET_FILE_PRICES_GRID,
    payload: {
      promise: axios
        .get(`${constants.BASE_URL}/api/maintenances/prices-grid/download`, {
          withCredentials: true,
          params: { clubId, anio },
          responseType: "blob"
        })
        .then(({ data }) => {
          FileDownload(data, `ParrilaPrecios_${anio || ""}.csv`);
        })
    }
  };
};

export const getReportPaymentLinesRequest = (
  clubId: string,
  fechaInicio: string,
  fechaFin: string,
  membresiaIdPublico: string,
  idPersona: string
) => {
  return axios.get(
    `${constants.BASE_URL}${REPORTS_BASE_URL}/report-payment-lines`,
    {
      withCredentials: true,
      params: { clubId, fechaInicio, fechaFin, membresiaIdPublico, idPersona }
    }
  );
};

export const downloadPrecalculo = (
  anio: any,
  mes: any,
  mesNombre: any,
  callback: any
) => {
  axios
    .get(
      `${constants.BASE_URL}${REPORTS_BASE_URL}/comissions/precalculo/download/${anio}/${mes}`,
      {
        withCredentials: true,
        responseType: "blob"
      }
    )
    .then(({ data }) => {
      FileDownload(data, `ReportePrecalculo_${mesNombre}_${anio}.csv`);
      callback();
    })
    .catch(err => {
      errorNotification("No existen datos en el periodo especifidado");
      callback();
    });
};

export const downloadSimulacionCierre = (callback: any) => {
  axios
    .get(
      `${constants.BASE_URL}${REPORTS_BASE_URL}/comissions/simulacion-cierre/download`,
      {
        withCredentials: true,
        responseType: "blob"
      }
    )
    .then(({ data }) => {
      FileDownload(data, `ReporteSimulacionCierre.csv`);
      callback();
    })
    .catch(err => {
      errorNotification(
        "No se pudo descargar el reporte, comuníquese con sistemas"
      );
      callback();
    });
};

export const downloadBlackpass = (
  anio: any,
  mes: any,
  mesNombre: any,
  callback: any
) => {
  axios
    .get(
      `${constants.BASE_URL}${REPORTS_BASE_URL}/sekura/blackpass/download/${anio}/${mes}`,
      {
        withCredentials: true,
        responseType: "blob"
      }
    )
    .then(({ data }) => {
      FileDownload(data, `ReporteBlackpass_${mesNombre}_${anio}.csv`);
      callback();
    })
    .catch(err => {
      errorNotification("No existen datos en el periodo especifidado");
      callback();
    });
};

export const getAdeudos = (params: any) => {
  return axios.get(`${constants.BASE_URL}/api/maintenances/adeudos`, {
    withCredentials: true,
    params
  });
};

export const countAdeudos = (params: any) => {
  return axios.get(`${constants.BASE_URL}/api/maintenances/adeudos/count`, {
    withCredentials: true,
    params
  });
};

export const downloadAdeudos = (params: any, callback: any) => {
  axios
    .get(`${constants.BASE_URL}/api/maintenances/adeudos/download`, {
      withCredentials: true,
      responseType: "blob",
      params
    })
    .then(({ data }) => {
      FileDownload(data, `ReporteAdeudos.csv`);
      callback();
    })
    .catch(err => {
      errorNotification("Error en la descarga del archivo");
      callback();
    });
};

export const downloadReactivaciones = (
  fechaInicio: any,
  fechaFin: any,
  callback: any
) => {
  axios
    .get(`${constants.BASE_URL}${REPORTS_BASE_URL}/reactivaciones/download`, {
      withCredentials: true,
      responseType: "blob",
      params: { fechaInicio, fechaFin }
    })
    .then(({ data }) => {
      FileDownload(data, `ReporteReactivaciones.csv`);
      callback && callback();
    })
    .catch(err => {
      errorNotification("No existen datos en el periodo especifidado");
      callback && callback();
    });
};

export const downloadVentasPortal = (callback: any) => {
  axios
    .get(
      `${constants.BASE_URL}${REPORTS_BASE_URL}/ventas/portal-web/download`,
      {
        withCredentials: true,
        responseType: "blob"
      }
    )
    .then(({ data }) => {
      FileDownload(data, `ReporteVentasPortalWeb.csv`);
      callback && callback();
    })
    .catch(err => {
      errorNotification("No se pudo descargar el reporte");
      callback && callback();
    });
};

export const getReportPartnerMembershipDirectory = (params: any) => {
  const url = `${constants.BASE_URL}${REPORTS_BASE_URL}/report-partner-membership-directory`;
  const config = { withCredentials: true, params };
  return axios.get(url, config);
};

export const downloadReportPartnerMembershipDirectory = (
  params: any,
  _callback
) => {
  return axios
    .get(
      `${constants.BASE_URL}${REPORTS_BASE_URL}/report-partner-membership-directory/download`,
      { withCredentials: true, params, responseType: "blob" }
    )
    .then(({ data }) => {
      if (!!_callback) {
        _callback();
      }

      FileDownload(
        data,
        `ReporteDirectorioMembresiaSocio_${moment().format(
          "YYYY-MM-DD HH:mm"
        )}.csv`
      );
    });
};

export const getReportPartnerMembershipTransfers = (params: any) => {
  const url = `${constants.BASE_URL}${REPORTS_BASE_URL}/report-partner-membership-transfers`;
  const config = { withCredentials: true, params };
  return axios.get(url, config);
};

export const downloadReportPartnerMembershipTransfers = (
  params: any
): IAction => {
  return {
    type: actionTypes.GET_FILE_CAT_PRELIMINAR,
    payload: {
      promise: axios
        .get(
          `${constants.BASE_URL}${REPORTS_BASE_URL}/report-partner-membership-transfers/download`,
          { withCredentials: true, params, responseType: "blob" }
        )
        .then(({ data }) => {
          FileDownload(
            data,
            `ReporteTraspasosMembresiaSocio_${moment().format(
              "YYYY-MM-DD HH:mm"
            )}.csv`
          );
        })
    }
  };
};

export const getReportPartnerMembershipWithoutMonthCharge = () => {
  const url = `${constants.BASE_URL}${REPORTS_BASE_URL}/report-partner-membership-without-month-charge`;
  const config = { withCredentials: true, params: {} };
  return axios.get(url, config);
};

export const downloadReportPartnerMembershipWithoutMonthCharge = (): IAction => {
  return {
    type: actionTypes.GET_FILE_CAT_PRELIMINAR,
    payload: {
      promise: axios
        .get(
          `${constants.BASE_URL}${REPORTS_BASE_URL}/report-partner-membership-without-month-charge/download`,
          { withCredentials: true, params: {}, responseType: "blob" }
        )
        .then(({ data }) => {
          FileDownload(
            data,
            `ListadoMembresiasSocioSincargoDelMes_${moment().format(
              "YYYY-MM-DD HH:mm"
            )}.csv`
          );
        })
    }
  };
};

export const executeGenerateChargesForPartnerMembershipsWithoutMonthCharge = () => {
  const url = `${constants.BASE_URL}${REPORTS_BASE_URL}/report-partner-membership-without-month-charge/execute`;
  const config = { withCredentials: true };
  return axios.post(url, {}, config);
};

export const comisionesTycOpciones = () => {
  return axios.get(
    `${constants.BASE_URL}${REPORTS_BASE_URL}/comissions-tyc/opciones-periodos`,
    {
      withCredentials: true
    }
  );
};

export const comisionesTycPendientes = (numeroEmpleado: any) => {
  return axios.get(
    `${constants.BASE_URL}${REPORTS_BASE_URL}/comissions-tyc/pendientes/${numeroEmpleado}`,
    {
      withCredentials: true
    }
  );
};

export const comisionesTycPagadas = (
  numeroEmpleado: any,
  periodo: any,
  quincena: any
) => {
  return axios.get(
    `${constants.BASE_URL}${REPORTS_BASE_URL}/comissions-tyc/pagadas/${numeroEmpleado}/${periodo}/${quincena}`,
    {
      withCredentials: true
    }
  );
};

export const downloadReportDeleteCatForzosoFileId = filters => {
  const url = `${constants.BASE_URL}${REPORTS_BASE_URL}/misc-reports/delete-cat-forzoso/download`;
  const config = {
    withCredentials: true,
    params: { ...filters }
  };
  return axios.get(url, config);
};
