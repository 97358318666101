import { Preview } from "../../../../types/IAction";
import {
  GetCmsDeportivaPeriodo,
  GetCmsDeportivaAutorizacion,
  PutCmsDeportivaAutorizar,
  PutCmsDeportivaRechazo,
  PutCmsDeportivaReasignacion
} from "../../../reducers/comisionesV2";
import * as actions from "../../../actions/comisionesV2";
import { getCmsDeportivaPeriodo } from "../../../actions/comisionesV2";

export interface DAutorizacionDispatcher {
  getCmsDeportivaPeriodo: () => Promise<Preview<GetCmsDeportivaPeriodo>>;
  getCmsDeportivaAutorizacion: (
    params?: any
  ) => Promise<Preview<GetCmsDeportivaAutorizacion>>;
  putCmsDeportivaAutorizar: (
    body: any
  ) => Promise<Preview<PutCmsDeportivaAutorizar>>;
  putCmsDeportivaRechazo: (id: any) => Promise<Preview<PutCmsDeportivaRechazo>>;
  putCmsDeportivaReasignacion: (
    body: any
  ) => Promise<Preview<PutCmsDeportivaReasignacion>>;
}

export const dAutorizacionDispatcher: (
  dispatch: (...args) => Promise<Preview<any>>
) => DAutorizacionDispatcher = dispatch => ({
  getCmsDeportivaPeriodo: () => {
    return dispatch(getCmsDeportivaPeriodo());
  },
  getCmsDeportivaAutorizacion: (params?: any) => {
    return dispatch(actions.getCmsDeportivaAutorizacion(params));
  },
  putCmsDeportivaAutorizar: (body: any) => {
    return dispatch(actions.putCmsDeportivaAutorizar(body));
  },
  putCmsDeportivaRechazo: (id: any) => {
    return dispatch(actions.putCmsDeportivaRechazar(id));
  },
  putCmsDeportivaReasignacion: (body: any) => {
    return dispatch(actions.putCmsDeportivaReasignar(body));
  }
});
