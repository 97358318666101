import FormBuilderConfig from "../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import ITextBuilder from "../../../toolkit/baseForms2/iTextBuilder/ITextBuilder";
import IButtonBuilder from "../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";

type Inputs = "nombre" | "correo";
type Buttons = "aceptar" | "cancelar";

export const representanteFormConfig: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "nombre",
      label: "Representante de Sports World",
      bts: {
        xCol: 12
      },
      component: ITextBuilder,
      props: {
        disabled: values => values.esTitular == "1"
      }
    },
    {
      name: "correo",
      label: "Correo del representante",
      bts: {
        xCol: 12
      },
      component: ITextBuilder,
      props: {
        disabled: values => values.esTitular == "1"
      }
    }
  ],
  buttons: [
    {
      name: "aceptar",
      label: "Aceptar",
      isSubmit: true,
      bts: {
        mCol: 3,
        mOff: 6
      },
      component: IButtonBuilder
    },
    {
      name: "cancelar",
      label: "Cancelar",
      isSubmit: false,
      bts: {
        mCol: 3
      },
      component: IButtonBuilder
    }
  ],
  validations: {
    nombre: {
      required: true
    },
    correo: {
      email: true,
      required: true
    }
  },
  rows: [["nombre", "correo", "aceptar", "cancelar"]]
};
