import React, { Component } from "react";
import { Router } from "../../../toolkit/decorators/Artifact";
import Layout from "../../components/layout";
import { Helmet } from "react-helmet";
import Carousel2 from "../../../toolkit/carousel2/Carousel2";
import AVentaContainer from "./steps/AVenta";
import BAsignacionContainer from "./steps/BAsignacion";
import CImparticionContainer from "./steps/CImparticion";
import DAutorizacionContainer from "./steps/DAutorizacion";
import ComisionesDeportivaComponent from "../../components/comisionesDeportivaV2Carrusel";
import { userHasPermission } from "../../../utils/accessTree";
import permissionCodes from "../../../utils/permissionCodes";

// Permisos para ver pasos del carrusel
const {
  COMISIONES_V2_STEP_VENTAS: STEP_VENTAS,
  COMISIONES_V2_STEP_ASIGNACION: STEP_ASIGNACION,
  COMISIONES_V2_STEP_IMPARTICION: STEP_IMPARTICION,
  COMISIONES_V2_STEP_AUTORIZACION: STEP_AUTORIZACION
} = permissionCodes;

interface Props {}
interface State {
  step: number;
  permStep1: boolean;
  permStep2: boolean;
  permStep3: boolean;
  permStep4: boolean;
}

@Router()
export default class ComisionesDeportivaCarrusel extends Component<
  Props,
  State
> {
  state: Readonly<State> = {
    step: null,
    permStep1: false,
    permStep2: false,
    permStep3: false,
    permStep4: false
  };

  componentDidMount(): void {
    const permStep1 = userHasPermission(STEP_VENTAS);
    const permStep2 = userHasPermission(STEP_ASIGNACION);
    const permStep3 = userHasPermission(STEP_IMPARTICION);
    const permStep4 = userHasPermission(STEP_AUTORIZACION);
    let step = 0;

    if (permStep1) {
      step = 1;
    } else if (permStep2) {
      step = 2;
    } else if (permStep3) {
      step = 3;
    } else if (permStep4) {
      step = 4;
    }

    this.setState({
      step,
      permStep1,
      permStep2,
      permStep3,
      permStep4
    });
  }

  render() {
    // Calculando el primer paso dependiendo de los permisos
    if (!this.state.step) {
      return null;
    }

    // Si no tiene permisos para ningun paso, mostrar mensaje
    if (this.state.step === 0) {
      return (
        <main>
          <Layout>
            <Helmet title="Comisiones" />
            <ComisionesDeportivaComponent />
          </Layout>
        </main>
      );
    }

    return (
      <main>
        <Layout>
          <Helmet title="Comisiones" />
          <Carousel2 active={true} step={this.state.step}>
            <AVentaContainer
              data-carousel-name="Información de venta"
              data-carousel-enabled={this.state.permStep1}
              data-carousel-disabled-tooltip="No tiene permisos para ver las ventas"
              {...({} as any)}
            />

            {/* Comentarios permitidos */}
            <BAsignacionContainer
              data-carousel-name="Asignación de clases"
              data-carousel-enabled={this.state.permStep2}
              data-carousel-disabled-tooltip="No tiene permisos para asignar clases"
              {...({} as any)}
            />
            <CImparticionContainer
              data-carousel-name="Confirmación de impartición"
              data-carousel-enabled={this.state.permStep3}
              data-carousel-disabled-tooltip="No tiene permisos para confirmar la impartición"
              {...({} as any)}
            />
            <DAutorizacionContainer
              data-carousel-name="Autorización de comisiones"
              data-carousel-enabled={this.state.permStep4}
              data-carousel-disabled-tooltip="No tiene permisos para autorizar comisiones"
              {...({} as any)}
            />
          </Carousel2>
        </Layout>
      </main>
    );
  }
}
