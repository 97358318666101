import FormBuilderConfig from "../../../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import IButtonBuilder from "../../../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import ISelectBuilder from "../../../../../toolkit/baseForms2/iSelectBuilder/ISelectBuilder";

export type Inputs = "razonRechazo";
export type Buttons = "aceptar" | "cancelar";

export const formConfigRechazar: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "razonRechazo",
      label: "* Razon de rechazo",
      bts: {
        mCol: 12
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "Seleccione",
        data: [
          {
            value: "Trámite administrativo del empleado",
            label: "Trámite administrativo del empleado"
          },
          {
            value: "Error de asignación",
            label: "Error de asignación"
          },
          {
            value: "Otra razón",
            label: "Otra razón"
          }
        ]
      }
    }
  ],
  buttons: [
    {
      name: "aceptar",
      label: "Aceptar",
      isSubmit: true,
      component: IButtonBuilder,
      bts: {
        mCol: 4
      }
    },
    {
      name: "cancelar",
      label: "Cancelar",
      bts: {
        mCol: 4
      }
    }
  ],
  validations: {
    razonRechazo: {
      required: true
    }
  },
  rows: [["razonRechazo"], ["aceptar", "cancelar"]]
};
