import React from "react";
import PagedTable from "../common/PagedTable";
import { Container } from "reactstrap";
import { EmpresaConvenioDispatcher } from "../../containers/empresaConvenio/EmpresaConvenio.dispatcher";
import { EmpresaConvenioStore } from "../../containers/empresaConvenio/EmpresaConvenio.store";
import EmpresaConvenio from "../../../types/EmpresaConvenio";
import {
  Router,
  Styled,
  RouterProps
} from "../../../toolkit/decorators/Artifact";
import s from "./styles.scss";
import FormBuilder from "../../../toolkit/baseForms2/formBuilder/FormBuilder";
import { formConfig } from "./EmpresaConvenio.form";
import ISelectBuilder from "../../../toolkit/baseForms2/iSelectBuilder/ISelectBuilder";
import {
  successNotification,
  errorNotification
} from "../../../utils/notifications";

interface Props
  extends EmpresaConvenioDispatcher,
    EmpresaConvenioStore,
    RouterProps {
  actionCompanie: (company: EmpresaConvenio) => void;
}

interface State {
  pagedFields: any;
}

@Router()
@Styled(s)
export default class EmpresaConvenioComponent extends React.Component<
  Props,
  State
> {
  state = {
    pagedFields: {} as any
  };

  columns = [
    { name: "Id", id: "id" },
    { name: "Nombre", id: "nombre" },
    { name: "Estatus", id: "estatus" },
    { name: "Creación", id: "fechaCreacion" },
    { name: "Actualización", id: "fechaActualizacion" },
    { headerActions: [{ name: "add", f: this.props.actionCompanie }] }
  ];

  async componentDidMount() {
    const preview = await this.props.getCompanies();
  }

  actions = [
    { name: "edit", f: this.props.actionCompanie },
    { name: "status", f: e => this.props.actionCompanie(e) }
  ];

  handleDeleteCompanie = async company => {
    company.estatus = company.estatus == "ACTIVO" ? "INACTIVO" : "ACTIVO";
    const preview = await this.props.updateCompany(company);

    if (preview.fulfilled) {
      successNotification("Empresa actualizada correctamente");
      this.search(this.state.pagedFields);
    } else {
      errorNotification("Error de actualización, intente de nuevo más tarde");
    }
  };

  submit = fields => {
    const pagedFields = {
      ...this.state.pagedFields,
      ...fields
    };
    this.setState({ pagedFields });
    this.search(pagedFields);
  };

  fetchData = ({ pageSize: size, page }) => {
    const pagedFields = {
      ...this.state.pagedFields,
      size,
      page
    };
    this.setState({ pagedFields });
    this.search(pagedFields);
  };

  search = pagedFields => {
    this.props.getCompanies(pagedFields);
  };

  render() {
    return (
      <>
        <Container className="p-0 mt-3">
          <FormBuilder
            config={formConfig}
            processing={this.props.gettingEmpresasConvenio}
            loading={this.props.updatingEmpresaConvenio}
            submit={this.submit}
          >
            <ISelectBuilder
              name={"estatus"}
              data={[
                { value: "ACTIVO", label: "Activo" },
                { value: "INACTIVO", label: "Inactivo" }
              ]}
            />
          </FormBuilder>

          <PagedTable
            data={this.props.empresasConvenio || []}
            columns={this.columns}
            actions={this.actions}
            fetchData={this.fetchData}
            searching={this.props.gettingEmpresasConvenio}
          />
        </Container>
      </>
    );
  }
}
