export const hiddenIfNotBusquedaPeriodo = formValue => !!formValue.tipoBusqueda;

export const hiddenIfNotBusquedaFactura = formValue =>
  formValue.tipoBusqueda !== "FACTURA";

export const requiredIfBusquedaPeriodo = {
  validator: (value, formValue) => !formValue.tipoBusqueda && !value,
  message: () => "Campo obligatorio"
};

export const requiredIfBusquedaFactura = {
  validator: (value, formValue) =>
    formValue.tipoBusqueda === "FACTURA" && !value,
  message: () => "Campo obligatorio"
};
