import FormBuilderConfig from "../../../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import IButtonBuilder from "../../../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import IDateBuilder from "../../../../../toolkit/baseForms2/iDateBuilder/IDateBuilder";
import ISelectBuilder from "../../../../../toolkit/baseForms2/iSelectBuilder/ISelectBuilder";
import ITextBuilder from "../../../../../toolkit/baseForms2/iTextBuilder/ITextBuilder";
import IClubSelectorBuilder from "../../../../../toolkit/baseForms2Commons/iClubSelectorBuilder/IClubSelectorBuilder";
import IUserSelectorBuilder from "../../../../../toolkit/baseForms2Commons/iUserSelectorBuilder/IUserSelectorBuilder";
import * as validators from "./AVenta.validators";
import { userHasPermission } from "../../../../../utils/accessTree";
import permissionCodes from "../../../../../utils/permissionCodes";

const { COMISIONES_V2_MULTICLUB: MULTICLUB } = permissionCodes;

export type Inputs =
  | "tipoBusqueda"
  | "idClub"
  | "idProducto"
  | "fechaInicio"
  | "fechaFin"
  | "idEmpleado"
  | "folioFactura";
export type Buttons = "buscar";

export const formConfig: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "tipoBusqueda",
      label: "* Tipo de búsqueda",
      bts: {
        mCol: 3
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "Producto",
        data: [
          { value: "EMPLEADO", label: "Empleado" },
          { value: "FACTURA", label: "Factura" }
        ]
      }
    },
    {
      name: "idClub",
      label: "Club",
      bts: {
        mCol: 3
      },
      component: IClubSelectorBuilder,
      props: {
        hidden: validators.hiddenIfNotBusquedaProducto,
        useSessionClub: true
      }
    },
    {
      name: "idProducto",
      label: "Producto",
      bts: {
        mCol: 12
      },
      props: {
        hidden: validators.hiddenIfNotBusquedaProducto
      }
    },
    {
      name: "fechaInicio",
      label: "Vendido desde",
      bts: {
        mCol: 3
      },
      component: IDateBuilder,
      props: {
        hidden: validators.hiddenIfNotBusquedaProducto
      }
    },
    {
      name: "fechaFin",
      label: "Vendido hasta",
      bts: {
        mCol: 3
      },
      component: IDateBuilder,
      props: {
        hidden: validators.hiddenIfNotBusquedaProducto
      }
    },
    {
      name: "idEmpleado",
      label: "* Empleado",
      bts: {
        mCol: 6
      },
      component: IUserSelectorBuilder,
      props: {
        hidden: validators.hiddenIfNotBusquedaEmpleado
      }
    },
    {
      name: "folioFactura",
      label: "* Folio de factura",
      bts: {
        mCol: 3
      },
      component: ITextBuilder,
      props: {
        emptyLabel: "Seleccione una opción",
        options: [],
        hidden: validators.hiddenIfNotBusquedaFactura
      }
    }
  ],
  buttons: [
    {
      name: "buscar",
      label: "Buscar",
      isSubmit: true,
      component: IButtonBuilder,
      bts: {
        mOff: 9,
        mCol: 3
      },
      props: {
        addTopMargin: false
      }
    }
  ],
  validations: {
    idEmpleado: {
      requiredIfBusquedaEmpleado: validators.requiredIfBusquedaEmpleado
    },
    folioFactura: {
      requiredIfBusquedaFactura: validators.requiredIfBusquedaFactura
    }
  },
  rows: [
    [
      "tipoBusqueda",
      "idClub",
      "fechaInicio",
      "fechaFin",
      "idEmpleado",
      "folioFactura"
    ],
    ["idProducto"],
    ["buscar"]
  ]
};
