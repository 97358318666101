import React, { Component } from "react";
import { Container } from "reactstrap";
import { Router } from "../../../toolkit/decorators/Artifact";

interface Props {}
interface State {}

@Router()
export default class ComisionesDeportivaComponent extends Component<
  Props,
  State
> {
  componentDidMount(): void {}

  render() {
    return (
      <Container>
        <div
          className="alert alert-danger h-100 w-100 mt-3"
          style={{ textAlign: "center" }}
          role="alert"
        >
          <p>
            No tiene permisos para operar comisiones, comuníquese con Mesa de
            Ayuda
          </p>
        </div>
      </Container>
    );
  }
}
