import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import {
  errorNotification,
  ERROR,
  successNotification
} from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";
import { string } from "prop-types";

/// Definición del estado
interface State {
  expediente: any;
  expedienteLoading: boolean;

  expedienteMembresia: any;
  expedienteMembresiaLoading: boolean;

  expedienteInvitado: any;
  expedienteInvitadoLoading: boolean;

  archivoRefresh: any;
  archivoRefreshLoading: boolean;

  archivo: any;
  archivoLoading: boolean;

  archivoVigente: any;
  archivoVigenteLoading: boolean;

  enviandoCorreo: boolean;

  actualizandoStatus: boolean;

  archivoFirmas: any[];
  archivoFirmasError: string;
  archivoFirmasLoading: boolean;

  condensados: any;
  condensadosLoading: boolean;

  autorizando: boolean;

  cincelPregeneracionLoading: boolean;
  cincelPregeneracionSuccess: boolean;

  cincelFirmasLoading: boolean;
}

const initialState: State = {
  expediente: {},
  expedienteLoading: false,

  expedienteMembresia: {},
  expedienteMembresiaLoading: false,

  expedienteInvitado: {},
  expedienteInvitadoLoading: false,

  archivoRefresh: {},
  archivoRefreshLoading: false,

  archivo: {},
  archivoLoading: false,

  archivoVigente: {},
  archivoVigenteLoading: false,

  enviandoCorreo: false,

  actualizandoStatus: false,

  archivoFirmas: [],
  archivoFirmasError: "",
  archivoFirmasLoading: false,

  condensados: [],
  condensadosLoading: false,

  autorizando: false,

  cincelPregeneracionLoading: false,
  cincelPregeneracionSuccess: false,

  cincelFirmasLoading: false
};
////

export default function expedientesReducer(
  state: State = initialState,
  action: IAction
): State {
  const snapshot = snapshotAction(state, action);

  // Llamada a resolver de snapshot
  state != snapshot &&
    !action.type.endsWith("_PENDING") &&
    action.meta &&
    action.meta.resolver &&
    action.meta.resolver({
      fulfilled: action.type.endsWith("_FULFILLED"),
      ...snapshot
    });

  return state != snapshot
    ? {
        ...state,
        ...snapshot
      }
    : state;
}

function snapshotAction(state: State = initialState, action: IAction) {
  switch (action.type) {
    /// GET_EXPEDIENTE_DATOS
    case `${actionTypes.GET_EXPEDIENTE_DATOS}_PENDING`: {
      return {
        expedienteLoading: true
      };
    }
    case `${actionTypes.GET_EXPEDIENTE_DATOS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        expedienteLoading: false,
        expediente: {}
      };
    }
    case `${actionTypes.GET_EXPEDIENTE_DATOS}_FULFILLED`: {
      return {
        expedienteLoading: false,
        expediente: action.payload.data || {}
      };
    } ////

    /// GET_EXPEDIENTE_MEMBRESIA_DATOS
    case `${actionTypes.GET_EXPEDIENTE_MEMBRESIA_DATOS}_PENDING`: {
      return {
        expedienteMembresiaLoading: true,
        expedienteMembresia: {}
      };
    }
    case `${actionTypes.GET_EXPEDIENTE_MEMBRESIA_DATOS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        expedienteMembresiaLoading: false,
        expedienteMembresia: {}
      };
    }
    case `${actionTypes.GET_EXPEDIENTE_MEMBRESIA_DATOS}_FULFILLED`: {
      return {
        expedienteMembresiaLoading: false,
        expedienteMembresia: action.payload.data || {}
      };
    } ////

    /// GET_EXPEDIENTE_INVITADO_DATOS
    case `${actionTypes.GET_EXPEDIENTE_INVITADO_DATOS}_PENDING`: {
      return {
        expedienteInvitadoLoading: true
      };
    }
    case `${actionTypes.GET_EXPEDIENTE_INVITADO_DATOS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        expedienteInvitadoLoading: false,
        expedienteInvitado: {}
      };
    }
    case `${actionTypes.GET_EXPEDIENTE_INVITADO_DATOS}_FULFILLED`: {
      return {
        expedienteInvitadoLoading: false,
        expedienteInvitado: action.payload.data || {}
      };
    } ////

    /// POST_EXPEDIENTES_ARCHIVO_REFRESH
    case `${actionTypes.POST_EXPEDIENTES_ARCHIVO_REFRESH}_PENDING`: {
      return {
        archivoRefreshLoading: true
      };
    }
    case `${actionTypes.POST_EXPEDIENTES_ARCHIVO_REFRESH}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        archivoRefreshLoading: false,
        archivoRefresh: {}
      };
    }
    case `${actionTypes.POST_EXPEDIENTES_ARCHIVO_REFRESH}_FULFILLED`: {
      return {
        archivoRefreshLoading: false,
        archivoRefresh: action.payload.data || {}
      };
    } ////

    /// GET_EXPEDIENTES_ARCHIVO_DATOS
    case `${actionTypes.GET_EXPEDIENTES_ARCHIVO_DATOS}_PENDING`: {
      return {
        archivoLoading: true
      };
    }
    case `${actionTypes.GET_EXPEDIENTES_ARCHIVO_DATOS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        archivoLoading: false,
        archivo: {}
      };
    }
    case `${actionTypes.GET_EXPEDIENTES_ARCHIVO_DATOS}_FULFILLED`: {
      return {
        archivoLoading: false,
        archivo: action.payload.data || {}
      };
    } ////

    /// GET_EXPEDIENTES_ARCHIVO_VIGENTE
    case `${actionTypes.GET_EXPEDIENTES_ARCHIVO_VIGENTE}_PENDING`: {
      return {
        archivoVigenteLoading: true
      };
    }
    case `${actionTypes.GET_EXPEDIENTES_ARCHIVO_VIGENTE}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        archivoVigenteLoading: false,
        archivoVigente: {}
      };
    }
    case `${actionTypes.GET_EXPEDIENTES_ARCHIVO_VIGENTE}_FULFILLED`: {
      return {
        archivoVigenteLoading: false,
        archivoVigente: action.payload.data || {}
      };
    } ////

    /// POST_EXPEDIENTES_ENVIO_CORREO
    case `${actionTypes.POST_EXPEDIENTES_ENVIO_CORREO}_PENDING`: {
      return {
        enviandoCorreo: true
      };
    }
    case `${actionTypes.POST_EXPEDIENTES_ENVIO_CORREO}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        enviandoCorreo: false
      };
    }
    case `${actionTypes.POST_EXPEDIENTES_ENVIO_CORREO}_FULFILLED`: {
      return {
        enviandoCorreo: false
      };
    } ////

    /// PUT_EXPEDIENTES_ARCHIVO_STATUS
    case `${actionTypes.PUT_EXPEDIENTES_ARCHIVO_STATUS}_PENDING`: {
      return {
        actualizandoStatus: true
      };
    }
    case `${actionTypes.PUT_EXPEDIENTES_ARCHIVO_STATUS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        actualizandoStatus: false
      };
    }
    case `${actionTypes.PUT_EXPEDIENTES_ARCHIVO_STATUS}_FULFILLED`: {
      return {
        actualizandoStatus: false
      };
    } ////

    /// GET_EXPEDIENTES_ARCHIVO_FIRMAS
    case `${actionTypes.GET_EXPEDIENTES_ARCHIVO_FIRMAS}_PENDING`: {
      return {
        archivoFirmas: [],
        archivoFirmasError: "",
        archivoFirmasLoading: true
      };
    }
    case `${actionTypes.GET_EXPEDIENTES_ARCHIVO_FIRMAS}_REJECTED`: {
      return {
        archivoFirmas: [],
        archivoFirmasError: action.payload.response.data.message,
        archivoFirmasLoading: false
      };
    }
    case `${actionTypes.GET_EXPEDIENTES_ARCHIVO_FIRMAS}_FULFILLED`: {
      return {
        archivoFirmas: action.payload.data,
        archivoFirmasError: "",
        archivoFirmasLoading: false
      };
    } ////

    /// GET_EXPEDIENTES_CONDENSADOS
    case `${actionTypes.GET_EXPEDIENTES_CONDENSADOS}_PENDING`: {
      return {
        condensados: {},
        condensadosLoading: true
      };
    }
    case `${actionTypes.GET_EXPEDIENTES_CONDENSADOS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        condensados: {},
        condensadosLoading: false
      };
    }
    case `${actionTypes.GET_EXPEDIENTES_CONDENSADOS}_FULFILLED`: {
      return {
        condensados: action.payload.data,
        condensadosLoading: false
      };
    } ////

    /// PUT_EXPEDIENTES_AUTORIZAR
    case `${actionTypes.PUT_EXPEDIENTES_AUTORIZAR}_PENDING`: {
      return {
        autorizando: true
      };
    }
    case `${actionTypes.PUT_EXPEDIENTES_AUTORIZAR}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        autorizando: false
      };
    }
    case `${actionTypes.PUT_EXPEDIENTES_AUTORIZAR}_FULFILLED`: {
      return {
        autorizando: false
      };
    } ////

    /// PUT_EXPEDIENTE_ARCHIVO_CINCEL_FIRMAS
    case `${actionTypes.PUT_EXPEDIENTE_ARCHIVO_CINCEL_FIRMAS}_PENDING`: {
      return {
        cincelFirmasLoading: true
      };
    }
    case `${actionTypes.PUT_EXPEDIENTE_ARCHIVO_CINCEL_FIRMAS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        cincelFirmasLoading: false
      };
    }
    case `${actionTypes.PUT_EXPEDIENTE_ARCHIVO_CINCEL_FIRMAS}_FULFILLED`: {
      return {
        cincelFirmasLoading: false
      };
    } ////

    /// POST_CINCEL_PREGENERACION
    case `${actionTypes.POST_CINCEL_PREGENERACION}_PENDING`: {
      return {
        cincelPregeneracionLoading: true,
        cincelPregeneracionSuccess: false
      };
    }
    case `${actionTypes.POST_CINCEL_PREGENERACION}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        cincelPregeneracionLoading: false,
        cincelPregeneracionSuccess: false
      };
    }
    case `${actionTypes.POST_CINCEL_PREGENERACION}_FULFILLED`: {
      successNotification(action.payload.data.message);
      return {
        cincelPregeneracionLoading: false,
        cincelPregeneracionSuccess: true
      };
    } ////

    default: {
      return state;
    }
  }
}
