import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import ModalFrame from "../../../../toolkit/modalFrame";
import { Container, Row } from "reactstrap";
import s from "./formBuilderModals.scss";
import FormBuilderConfig from "../../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import FormBuilder from "../../../../toolkit/baseForms2/formBuilder/FormBuilder";
import IButtonBuilder from "../../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import { Styled } from "../../../../toolkit/decorators/Artifact";

interface Props {
  isOpen: boolean;
  title: String;
  message: String;
  ok: (values: any) => void;
  cancel: () => void;
  formConfig: FormBuilderConfig<any, "aceptar" | "cancelar">;
  size?: string;
  values?: any;
  danger?: string;
}

interface State {
  processing: boolean;
}

@Styled(s)
export default class FormBuilderModal extends React.Component<Props, State> {
  state = {
    processing: false
  };

  submit = async values => {
    try {
      this.setState({ processing: true });
      await this.props.ok(values);
      this.setState({ processing: false });
    } catch (err) {
      this.setState({ processing: false });
    }
  };

  render() {
    if (!this.props.formConfig) {
      return null;
    }

    return (
      <ModalFrame
        title={this.props.title}
        isOpen={this.props.isOpen}
        toggle={() => this.props.cancel()}
        size={this.props.size || "md"}
      >
        <Container style={{ width: "90%" }} className="center-block p-0 mb-3">
          {!!this.props.danger && (
            <Row>
              <div className="alert alert-danger mb-4" role="alert">
                {this.props.danger}
              </div>
            </Row>
          )}
          <Row>
            <div>{this.props.message}</div>
          </Row>
          <FormBuilder
            config={this.props.formConfig}
            submit={this.submit}
            processing={this.state.processing}
            init={this.props.values}
          >
            <IButtonBuilder name="cancelar" onClick={this.props.cancel} />
            {this.props.children}
          </FormBuilder>
        </Container>
      </ModalFrame>
    );
  }
}

// export default withStyles(s)(FormBuilderModal);
