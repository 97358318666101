import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";

const BILLING_BASE_URL = "/api/billing";
const MAINTENANCES_API_URL = "/api/maintenances";

export const sendToBilling = (billingParams: object): IAction => {
  return {
    type: actionTypes.SEND_BILLING,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${BILLING_BASE_URL}/sendBilling`,
        billingParams,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const sendToAccrual = (billingParams: object): IAction => {
  return {
    type: actionTypes.SEND_ACCRUAL,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${BILLING_BASE_URL}/sendAccrualData/${billingParams.periodo}`,
        billingParams.body,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const cancelBill = (bill: object, uuid: string): IAction => {
  return {
    type: actionTypes.CANCEL_BILL,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${BILLING_BASE_URL}/${uuid}/cancel`,
        bill,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const remakeBill = (bill: object, uuid: string): IAction => {
  return {
    type: actionTypes.REMAKE_BILL,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${BILLING_BASE_URL}/${uuid}/remake`,
        bill,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getBillingCatalogs = (): IAction => {
  return {
    type: actionTypes.GET_BILLING_CATALOGS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${BILLING_BASE_URL}/getBillingCatalogs`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getBillingCatalogByKeyRequest = (catalogKey: string) => {
  return axios.get(
    `${constants.BASE_URL}${BILLING_BASE_URL}/getBillingCatalog/${catalogKey}`,
    {
      withCredentials: true
    }
  );
};

export const getBill = (uuid: string): IAction => {
  return {
    type: actionTypes.GET_BILL,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${BILLING_BASE_URL}/${uuid}`, {
        withCredentials: true
      })
    }
  };
};

export const getBillXml = (uuid: string): IAction => {
  return {
    type: actionTypes.GET_BILL,
    payload: window.open(
      `${constants.BASE_URL}${BILLING_BASE_URL}/${uuid}/xml`,
      "_blank"
    )
  };
};

export const openBillXml = (uuid: string) => {
  window.open(`${constants.BASE_URL}${BILLING_BASE_URL}/${uuid}/xml`, "_blank");
};

export const openBillXmlAddendas = (uuid: string) => {
  window.open(
    `${constants.BASE_URL}${BILLING_BASE_URL}/${uuid}/xml/addendas`,
    "_blank"
  );
};

export const openPaymentComplementXml = (uuid: string) => {
  window.open(
    `${constants.BASE_URL}${BILLING_BASE_URL}/${uuid}/payment-complement/xml`,
    "_blank"
  );
};

export const getAccrual = (): IAction => {
  return {
    type: actionTypes.GET_ACCRUAL,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${MAINTENANCES_API_URL}/accrual`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getReturnInvoice = (
  clubId: string,
  membresiaSocio: string
): IAction => {
  return {
    type: actionTypes.GET_RETURN_INVOICE,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${BILLING_BASE_URL}/returnInvoice/${clubId}/${membresiaSocio}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getPartnerMembershipInvoicesToReturnRequest = (
  clubId: string,
  membresiaSocio: string
) => {
  return axios.get(
    `${constants.BASE_URL}${BILLING_BASE_URL}/returnInvoice/${clubId}/${membresiaSocio}`,
    {
      withCredentials: true
    }
  );
};

export const getTransactionByReference = (referencia: string): IAction => {
  return {
    type: actionTypes.GET_TRANSACTION_BY_REFERENCE,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${BILLING_BASE_URL}/transacciones-por-referencia/${referencia}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getTransactionByReferenceRequest = (referencia: string) => {
  return axios.get(
    `${constants.BASE_URL}${BILLING_BASE_URL}/transacciones-por-referencia/${referencia}`,
    {
      withCredentials: true
    }
  );
};

export const updateAccrual = (data: object, idEsquema: string): IAction => {
  return {
    type: actionTypes.UPDATE_ACCRUAL,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${MAINTENANCES_API_URL}/accrual/${idEsquema}`,
        data,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const savePaidComplement = (data: object, uuid: string): IAction => {
  return {
    type: actionTypes.SAVE_PAID_COMPLEMENT,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${MAINTENANCES_API_URL}/venta/${uuid}`,
        data,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const savePaidComplementRequest = (data: object, uuid: string) => {
  return axios.post(
    `${constants.BASE_URL}${MAINTENANCES_API_URL}/venta/${uuid}`,
    data,
    {
      withCredentials: true
    }
  );
};

export const saveReturnInvoice = (data: object): IAction => {
  return {
    type: actionTypes.SAVE_RETURN_INVOICE,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${BILLING_BASE_URL}/returnInvoice/request`,
        data,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const saveReturnInvoiceRequest = (data: object) => {
  axios.post(
    `${constants.BASE_URL}${BILLING_BASE_URL}/returnInvoice/request`,
    data,
    {
      withCredentials: true
    }
  );
};

export const sendFinanceInvoiceByEmailRequest = (
  uuid: String,
  email: String
) => {
  return axios.post(
    `${constants.BASE_URL}${BILLING_BASE_URL}/sendFinanceInvoiceByEmail`,
    { uuidFactura: uuid, email },
    {
      withCredentials: true
    }
  );
};

export const postBillingAdm = (data: any) => {
  return axios.post(
    `${constants.BASE_URL}${BILLING_BASE_URL}/facturacion-adm`,
    data,
    {
      withCredentials: true
    }
  );
};

export const getBillingAdmChargesFromCsv = (
  csvFile: Blob,
  fileName: string
) => {
  const data = new FormData();
  data.append("file", csvFile);
  data.append("filename", fileName);

  return axios.post(
    `${constants.BASE_URL}${BILLING_BASE_URL}/facturacion-adm/charges-from-csv`,
    data,
    {
      withCredentials: true
    }
  );
};

export const clearBillingRedux = (): IAction => {
  return {
    type: actionTypes.CLEAR_BILLING_REDUX
  };
};

export const saleWithErrorRefactType1 = (saleId: string) => {
  return axios.post(
    `${constants.BASE_URL}${MAINTENANCES_API_URL}/venta/${saleId}/refacturar-type-a?`,
    null,
    {
      withCredentials: true
    }
  );
};

export const saleWithErrorRefactType2 = (saleId: string) => {
  return axios.post(
    `${constants.BASE_URL}${MAINTENANCES_API_URL}/venta/${saleId}/refacturar-type-b?`,
    null,
    {
      withCredentials: true
    }
  );
};

export const postContabilizar = (idFactura: any) => {
  return axios.post(
    `${constants.BASE_URL}${BILLING_BASE_URL}/contabilizar-erp/${idFactura}`,
    null,
    {
      withCredentials: true
    }
  );
};
