import DeportivaComision from "../../types/DeportivaComision";
import DeportivaEventoAsignable from "../../types/DeportivaEventoAsignable";
import DeportivaEventoAsignado from "../../types/DeportivaEventoAsignado";
import DeportivaVenta from "../../types/DeportivaVenta";
import IAction from "../../types/IAction";
import { readMessageBack } from "../../utils/readMessageBack";
import actionTypes from "../actionTypes";

export interface GetCmsProductosVenta {
  cmsProductosVenta: any[];
  gettingCmsProductosVenta: boolean;
  gettingCmsProductosVentaError: string;
}

export interface GetCmsVentas {
  cmsVentas: Page<DeportivaVenta>;
  gettingCmsVentas: boolean;
  gettingCmsVentasError: string;
}

export interface PutComisionVendedor {
  puttingCmsVendedor: boolean;
  puttingCmsVendedorError: string;
}

export interface PutCmsInstructorPrincipal {
  puttingCmsInstructorPrincipal: boolean;
  puttingCmsInstructorPrincipalError: string;
}

export interface PutCmsCoordinador {
  puttingCmsCoordinador: boolean;
  puttingCmsCoordinadorError: string;
}

export interface GetCmsProductosOperacion {
  cmsProductosOperacion: any;
  gettingCmsProductosOperacion: boolean;
  gettingCmsProductosOperacionError: string;
}

export interface GetCmsInstructorComisionable {
  cmsInstructorComisionable: any;
  gettingCmsInstructorComisionable: boolean;
  gettingCmsInstructorComisionableError: string;
}

export interface GetCmsClasesAsignables {
  cmsClasesAsignables: Page<DeportivaEventoAsignable>;
  gettingCmsClasesAsignables: boolean;
  gettingCmsClasesAsignablesError: string;
}

export interface PostCmsAsignacion {
  postingCmsAsignacion: boolean;
  postingCmsAsignacionError: string;
}

export interface GetCmsProductosAsignados {
  cmsProductosAsignados: any[];
  gettingCmsProductosAsignados: boolean;
  gettingCmsProductosAsignadosError: string;
}

export interface GetCmsClasesAsignadas {
  cmsClasesAsignadas: Page<DeportivaEventoAsignado>;
  gettingCmsClasesAsignadas: boolean;
  gettingCmsClasesAsignadasError: string;
}

export interface PutCmsClasesImparticion {
  puttingCmsClasesImparticion: boolean;
  puttingCmsClasesImparticionError: string;
}

export interface PutCmsClasesReasignacion {
  puttingCmsClasesReasignacion: boolean;
  puttingCmsClasesReasignacionError: string;
}

export interface GetCmsDeportivaPeriodo {
  cmsDeportivaPeriodo: any;
  gettingCmsDeportivaPeriodo: boolean;
  gettingCmsDeportivaPeriodoError: string;
}

export interface GetCmsDeportivaAutorizacion {
  cmsDeportivaAutorizacion: DeportivaComision[];
  gettingCmsDeportivaAutorizacion: boolean;
  gettingCmsDeportivaAutorizacionError: string;
}

export interface PutCmsDeportivaAutorizar {
  puttingCmsDeportivaAutorizar: boolean;
  puttingCmsDeportivaAutorizarError: string;
}

export interface PutCmsDeportivaReasignacion {
  puttingCmsDeportivaReasignacion: boolean;
  puttingCmsDeportivaReasignacionError: string;
}

export interface PutCmsDeportivaRechazo {
  puttingCmsDeportivaRechazo: boolean;
  puttingCmsDeportivaRechazoError: string;
}

interface State
  extends GetCmsProductosVenta,
    GetCmsVentas,
    PutComisionVendedor,
    PutCmsInstructorPrincipal,
    PutCmsCoordinador,
    GetCmsProductosOperacion,
    GetCmsInstructorComisionable,
    GetCmsClasesAsignables,
    PostCmsAsignacion,
    GetCmsProductosAsignados,
    GetCmsClasesAsignadas,
    PutCmsClasesImparticion,
    PutCmsClasesReasignacion,
    GetCmsDeportivaPeriodo,
    GetCmsDeportivaAutorizacion,
    PutCmsDeportivaAutorizar,
    PutCmsDeportivaReasignacion,
    PutCmsDeportivaRechazo {}

const initialState: State = {
  cmsProductosVenta: null,
  gettingCmsProductosVenta: false,
  gettingCmsProductosVentaError: null,

  cmsVentas: null,
  gettingCmsVentas: false,
  gettingCmsVentasError: null,

  puttingCmsVendedor: false,
  puttingCmsVendedorError: null,

  puttingCmsInstructorPrincipal: false,
  puttingCmsInstructorPrincipalError: null,

  puttingCmsCoordinador: false,
  puttingCmsCoordinadorError: null,

  cmsProductosOperacion: null,
  gettingCmsProductosOperacion: false,
  gettingCmsProductosOperacionError: null,

  cmsInstructorComisionable: null,
  gettingCmsInstructorComisionable: false,
  gettingCmsInstructorComisionableError: null,

  cmsClasesAsignables: null,
  gettingCmsClasesAsignables: false,
  gettingCmsClasesAsignablesError: null,

  postingCmsAsignacion: false,
  postingCmsAsignacionError: null,

  cmsProductosAsignados: null,
  gettingCmsProductosAsignados: false,
  gettingCmsProductosAsignadosError: null,

  cmsClasesAsignadas: null,
  gettingCmsClasesAsignadas: false,
  gettingCmsClasesAsignadasError: null,

  puttingCmsClasesImparticion: false,
  puttingCmsClasesImparticionError: null,

  puttingCmsClasesReasignacion: false,
  puttingCmsClasesReasignacionError: null,

  cmsDeportivaAutorizacion: null,
  gettingCmsDeportivaAutorizacion: false,
  gettingCmsDeportivaAutorizacionError: null,

  cmsDeportivaPeriodo: null,
  gettingCmsDeportivaPeriodo: false,
  gettingCmsDeportivaPeriodoError: null,

  puttingCmsDeportivaAutorizar: false,
  puttingCmsDeportivaAutorizarError: null,

  puttingCmsDeportivaReasignacion: false,
  puttingCmsDeportivaReasignacionError: null,

  puttingCmsDeportivaRechazo: false,
  puttingCmsDeportivaRechazoError: null
};

export default function comisionesV2Reducer(
  state: State = initialState,
  action: IAction
): State {
  const snapshot = snapshotAction(state, action);

  // Llamada a resolver de snapshot
  state != snapshot &&
    !action.type.endsWith("_PENDING") &&
    action.meta &&
    action.meta.resolver &&
    action.meta.resolver({
      fulfilled: action.type.endsWith("_FULFILLED"),
      ...snapshot
    });

  return state != snapshot
    ? {
        ...state,
        ...snapshot
      }
    : state;
}

function snapshotAction(state: State = initialState, action: IAction) {
  switch (action.type) {
    // GET_COMISIONES_V2_PRODUCTOS_VENTA
    case `${actionTypes.GET_COMISIONES_V2_PRODUCTOS_VENTA}_PENDING`: {
      return {
        gettingCmsProductosVenta: true,
        gettingCmsProductosVentaError: null
      };
    }
    case `${actionTypes.GET_COMISIONES_V2_PRODUCTOS_VENTA}_REJECTED`: {
      return {
        cmsProductosVenta: null,
        gettingCmsProductosVenta: false,
        gettingCmsProductosVentaError: readMessageBack(action)
      };
    }
    case `${actionTypes.GET_COMISIONES_V2_PRODUCTOS_VENTA}_FULFILLED`: {
      return {
        cmsProductosVenta: action.payload.data,
        gettingCmsProductosVenta: false,
        gettingCmsProductosVentaError: null
      };
    }

    // GET_COMISIONES_V2_VENTAS
    case `${actionTypes.GET_COMISIONES_V2_VENTAS}_PENDING`: {
      return {
        gettingCmsVentas: true,
        gettingCmsVentasError: null
      };
    }
    case `${actionTypes.GET_COMISIONES_V2_VENTAS}_REJECTED`: {
      return {
        cmsVentas: null,
        gettingCmsVentas: false,
        gettingCmsVentasError: readMessageBack(action)
      };
    }
    case `${actionTypes.GET_COMISIONES_V2_VENTAS}_FULFILLED`: {
      return {
        cmsVentas: action.payload.data,
        gettingCmsVentas: false,
        gettingCmsVentasError: null
      };
    }

    // PUT_COMISIONES_V2_VENDEDOR
    case `${actionTypes.PUT_COMISIONES_V2_VENDEDOR}_PENDING`: {
      return {
        puttingCmsVendedor: true,
        puttingCmsVendedorError: null
      };
    }
    case `${actionTypes.PUT_COMISIONES_V2_VENDEDOR}_REJECTED`: {
      return {
        puttingCmsVendedor: false,
        puttingCmsVendedorError: readMessageBack(action)
      };
    }
    case `${actionTypes.PUT_COMISIONES_V2_VENDEDOR}_FULFILLED`: {
      return {
        puttingCmsVendedor: false,
        puttingCmsVendedorError: null
      };
    }

    // PUT_COMISIONES_V2_INSTRUCTOR_PRINCIPAL
    case `${actionTypes.PUT_COMISIONES_V2_INSTRUCTOR_PRINCIPAL}_PENDING`: {
      return {
        puttingCmsInstructorPrincipal: true,
        puttingCmsInstructorPrincipalError: null
      };
    }
    case `${actionTypes.PUT_COMISIONES_V2_INSTRUCTOR_PRINCIPAL}_REJECTED`: {
      return {
        puttingCmsInstructorPrincipal: false,
        puttingCmsInstructorPrincipalError: readMessageBack(action)
      };
    }
    case `${actionTypes.PUT_COMISIONES_V2_INSTRUCTOR_PRINCIPAL}_FULFILLED`: {
      return {
        puttingCmsInstructorPrincipal: false,
        puttingCmsInstructorPrincipalError: null
      };
    }

    // PUT_COMISIONES_V2_COORDINADOR
    case `${actionTypes.PUT_COMISIONES_V2_COORDINADOR}_PENDING`: {
      return {
        puttingCmsCoordinador: true,
        puttingCmsCoordinadorError: null
      };
    }
    case `${actionTypes.PUT_COMISIONES_V2_COORDINADOR}_REJECTED`: {
      return {
        puttingCmsCoordinador: false,
        puttingCmsCoordinadorError: readMessageBack(action)
      };
    }
    case `${actionTypes.PUT_COMISIONES_V2_COORDINADOR}_FULFILLED`: {
      return {
        puttingCmsCoordinador: false,
        puttingCmsCoordinadorError: null
      };
    }

    // GET_COMISIONES_V2_PRODUCTOS_OPERACION
    case `${actionTypes.GET_COMISIONES_V2_PRODUCTOS_OPERACION}_PENDING`: {
      return {
        gettingCmsProductosOperacion: true,
        gettingCmsProductosOperacionError: null
      };
    }
    case `${actionTypes.GET_COMISIONES_V2_PRODUCTOS_OPERACION}_REJECTED`: {
      return {
        cmsProductosOperacion: null,
        gettingCmsProductosOperacion: false,
        gettingCmsProductosOperacionError: readMessageBack(action)
      };
    }
    case `${actionTypes.GET_COMISIONES_V2_PRODUCTOS_OPERACION}_FULFILLED`: {
      return {
        cmsProductosOperacion: action.payload.data,
        gettingCmsProductosOperacion: false,
        gettingCmsProductosOperacionError: null
      };
    }

    // GET_COMISIONES_V2_INSTRUCTOR_COMISIONABLE
    case `${actionTypes.GET_COMISIONES_V2_INSTRUCTOR_COMISIONABLE}_PENDING`: {
      return {
        gettingCmsInstructorComisionable: true,
        gettingCmsInstructorComisionableError: null
      };
    }
    case `${actionTypes.GET_COMISIONES_V2_INSTRUCTOR_COMISIONABLE}_REJECTED`: {
      return {
        cmsInstructorComisionable: null,
        gettingCmsInstructorComisionable: false,
        gettingCmsInstructorComisionableError: readMessageBack(action)
      };
    }
    case `${actionTypes.GET_COMISIONES_V2_INSTRUCTOR_COMISIONABLE}_FULFILLED`: {
      return {
        cmsInstructorComisionable: action.payload.data,
        gettingCmsInstructorComisionable: false,
        gettingCmsInstructorComisionableError: null
      };
    }

    // GET_COMISIONES_V2_PRODUCTOS_ASIGNADOS
    case `${actionTypes.GET_COMISIONES_V2_PRODUCTOS_ASIGNADOS}_PENDING`: {
      return {
        gettingCmsProductosAsignados: true,
        gettingCmsProductosAsignadosError: null
      };
    }
    case `${actionTypes.GET_COMISIONES_V2_PRODUCTOS_ASIGNADOS}_REJECTED`: {
      return {
        cmsProductosAsignados: null,
        gettingCmsProductosAsignados: false,
        gettingCmsProductosAsignadosError: readMessageBack(action)
      };
    }
    case `${actionTypes.GET_COMISIONES_V2_PRODUCTOS_ASIGNADOS}_FULFILLED`: {
      return {
        cmsProductosAsignados: action.payload.data,
        gettingCmsProductosAsignados: false,
        gettingCmsProductosAsignadosError: null
      };
    }

    // GET_COMISIONES_V2_CLASES_ASIGNABLES
    case `${actionTypes.GET_COMISIONES_V2_CLASES_ASIGNABLES}_PENDING`: {
      return {
        gettingCmsClasesAsignables: true,
        gettingCmsClasesAsignablesError: null
      };
    }
    case `${actionTypes.GET_COMISIONES_V2_CLASES_ASIGNABLES}_REJECTED`: {
      return {
        cmsClasesAsignables: null,
        gettingCmsClasesAsignables: false,
        gettingCmsClasesAsignablesError: readMessageBack(action)
      };
    }
    case `${actionTypes.GET_COMISIONES_V2_CLASES_ASIGNABLES}_FULFILLED`: {
      return {
        cmsClasesAsignables: action.payload.data,
        gettingCmsClasesAsignables: false,
        gettingCmsClasesAsignablesError: null
      };
    }

    // POST_COMISIONES_V2_ASIGNACION
    case `${actionTypes.POST_COMISIONES_V2_ASIGNACION}_PENDING`: {
      return {
        postingCmsAsignacion: true,
        postingCmsAsignacionError: null
      };
    }
    case `${actionTypes.POST_COMISIONES_V2_ASIGNACION}_REJECTED`: {
      return {
        postingCmsAsignacion: false,
        postingCmsAsignacionError: readMessageBack(action)
      };
    }
    case `${actionTypes.POST_COMISIONES_V2_ASIGNACION}_FULFILLED`: {
      return {
        postingCmsAsignacion: false,
        postingCmsAsignacionError: null
      };
    }

    // GET_COMISIONES_V2_CLASES_ASIGNADAS
    case `${actionTypes.GET_COMISIONES_V2_CLASES_ASIGNADAS}_PENDING`: {
      return {
        gettingCmsClasesAsignadas: true,
        gettingCmsClasesAsignadasError: null
      };
    }
    case `${actionTypes.GET_COMISIONES_V2_CLASES_ASIGNADAS}_REJECTED`: {
      return {
        cmsClasesAsignadas: null,
        gettingCmsClasesAsignadas: false,
        gettingCmsClasesAsignadasError: readMessageBack(action)
      };
    }
    case `${actionTypes.GET_COMISIONES_V2_CLASES_ASIGNADAS}_FULFILLED`: {
      return {
        cmsClasesAsignadas: action.payload.data,
        gettingCmsClasesAsignadas: false,
        gettingCmsClasesAsignadasError: null
      };
    }

    // PUT_COMISIONES_V2_EVENTO_IMPARTICION
    case `${actionTypes.PUT_COMISIONES_V2_EVENTO_IMPARTICION}_PENDING`: {
      return {
        puttingCmsClasesImparticion: true,
        puttingCmsClasesImparticionError: null
      };
    }
    case `${actionTypes.PUT_COMISIONES_V2_EVENTO_IMPARTICION}_REJECTED`: {
      return {
        puttingCmsClasesImparticion: false,
        puttingCmsClasesImparticionError: readMessageBack(action)
      };
    }
    case `${actionTypes.PUT_COMISIONES_V2_EVENTO_IMPARTICION}_FULFILLED`: {
      return {
        puttingCmsClasesImparticion: false,
        puttingCmsClasesImparticionError: null
      };
    }

    // PUT_COMISIONES_V2_EVENTO_REASIGNACION
    case `${actionTypes.PUT_COMISIONES_V2_EVENTO_REASIGNACION}_PENDING`: {
      return {
        puttingCmsClasesReasignacion: true,
        puttingCmsClasesReasignacionError: null
      };
    }
    case `${actionTypes.PUT_COMISIONES_V2_EVENTO_REASIGNACION}_REJECTED`: {
      return {
        puttingCmsClasesReasignacion: false,
        puttingCmsClasesReasignacionError: readMessageBack(action)
      };
    }
    case `${actionTypes.PUT_COMISIONES_V2_EVENTO_REASIGNACION}_FULFILLED`: {
      return {
        puttingCmsClasesReasignacion: false,
        puttingCmsClasesReasignacionError: null
      };
    }

    // GET_COMISIONES_V2_DEPORTIVA_PERIODO
    case `${actionTypes.GET_COMISIONES_V2_DEPORTIVA_PERIODO}_PENDING`: {
      return {
        gettingCmsDeportivaPeriodo: true,
        gettingCmsDeportivaPeriodoError: null
      };
    }
    case `${actionTypes.GET_COMISIONES_V2_DEPORTIVA_PERIODO}_REJECTED`: {
      return {
        cmsDeportivaPeriodo: null,
        gettingCmsDeportivaPeriodo: false,
        gettingCmsDeportivaPeriodoError: readMessageBack(action)
      };
    }
    case `${actionTypes.GET_COMISIONES_V2_DEPORTIVA_PERIODO}_FULFILLED`: {
      return {
        cmsDeportivaPeriodo: action.payload.data,
        gettingCmsDeportivaPeriodo: false,
        gettingCmsDeportivaPeriodoError: null
      };
    }

    // GET_COMISIONES_V2_DEPORTIVA_AUTORIZACION
    case `${actionTypes.GET_COMISIONES_V2_DEPORTIVA_AUTORIZACION}_PENDING`: {
      return {
        gettingCmsDeportivaAutorizacion: true,
        gettingCmsDeportivaAutorizacionError: null
      };
    }
    case `${actionTypes.GET_COMISIONES_V2_DEPORTIVA_AUTORIZACION}_REJECTED`: {
      return {
        cmsDeportivaAutorizacion: null,
        gettingCmsDeportivaAutorizacion: false,
        gettingCmsDeportivaAutorizacionError: readMessageBack(action)
      };
    }
    case `${actionTypes.GET_COMISIONES_V2_DEPORTIVA_AUTORIZACION}_FULFILLED`: {
      return {
        cmsDeportivaAutorizacion: action.payload.data,
        gettingCmsDeportivaAutorizacion: false,
        gettingCmsDeportivaAutorizacionError: null
      };
    }

    // PUT_COMISIONES_V2_DEPORTIVA_AUTORIZAR
    case `${actionTypes.PUT_COMISIONES_V2_DEPORTIVA_AUTORIZAR}_PENDING`: {
      return {
        puttingCmsDeportivaAutorizar: true,
        puttingCmsDeportivaAutorizarError: null
      };
    }
    case `${actionTypes.PUT_COMISIONES_V2_DEPORTIVA_AUTORIZAR}_REJECTED`: {
      return {
        puttingCmsDeportivaAutorizar: false,
        puttingCmsDeportivaAutorizarError: readMessageBack(action)
      };
    }
    case `${actionTypes.PUT_COMISIONES_V2_DEPORTIVA_AUTORIZAR}_FULFILLED`: {
      return {
        puttingCmsDeportivaAutorizar: false,
        puttingCmsDeportivaAutorizarError: null
      };
    }

    // PUT_COMISIONES_V2_DEPORTIVA_RECHAZAR
    case `${actionTypes.PUT_COMISIONES_V2_DEPORTIVA_RECHAZAR}_PENDING`: {
      return {
        puttingCmsDeportivaRechazo: true,
        puttingCmsDeportivaRechazoError: null
      };
    }
    case `${actionTypes.PUT_COMISIONES_V2_DEPORTIVA_RECHAZAR}_REJECTED`: {
      return {
        puttingCmsDeportivaRechazo: false,
        puttingCmsDeportivaRechazoError: readMessageBack(action)
      };
    }
    case `${actionTypes.PUT_COMISIONES_V2_DEPORTIVA_RECHAZAR}_FULFILLED`: {
      return {
        puttingCmsDeportivaRechazo: false,
        puttingCmsDeportivaRechazoError: null
      };
    }

    // PUT_COMISIONES_V2_DEPORTIVA_REASIGNAR
    case `${actionTypes.PUT_COMISIONES_V2_DEPORTIVA_REASIGNAR}_PENDING`: {
      return {
        puttingCmsDeportivaReasignacion: true,
        puttingCmsDeportivaReasignacionError: null
      };
    }
    case `${actionTypes.PUT_COMISIONES_V2_DEPORTIVA_REASIGNAR}_REJECTED`: {
      return {
        puttingCmsDeportivaReasignacion: false,
        puttingCmsDeportivaReasignacionError: readMessageBack(action)
      };
    }
    case `${actionTypes.PUT_COMISIONES_V2_DEPORTIVA_REASIGNAR}_FULFILLED`: {
      return {
        puttingCmsDeportivaReasignacion: false,
        puttingCmsDeportivaReasignacionError: null
      };
    }

    default: {
      return state;
    }
  }
}
