import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import FacturacionAdm from "../../components/facturacionAdm";
import Layout from "../../components/layout";

interface Props {}

class FacturacionAdmPage extends React.Component<Props> {
  state = {
    classifications: []
  };

  componentDidMount() {}

  render() {
    return (
      <main>
        <Helmet title="Annuities" />
        <Layout>
          <FacturacionAdm />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FacturacionAdmPage);
