import { Archivo } from "../types";
import React from "react";
import { Preview } from "../../../../types/IAction";
import ArchivoControlSm from "./archivoSm";
import ArchivoControlLg from "./archivoLg";
import FormBuilderConfig from "../../../../toolkit/baseForms2/formBuilder/FormBuilder.config";

interface Props {
  // Archivo por mostrar
  archivo: Archivo;

  // Identificador del usuario
  userId: number;

  // Indican qué botones mostrarán; por defecto se observan
  botonCorreo?: boolean;
  botonFirmaOEscanner?: boolean;
  botonVer?: boolean;
  botonRegenrar?: boolean;
  formConfigRegenerar?: FormBuilderConfig<any, "aceptar" | "cancelar">;
  formConfigInit?: any;

  // Métodos externos, para correo, regenerar o actualizar despues de firmar
  sendEmail?: (archivo: Archivo) => Promise<Preview<any>>;
  regenerar?: (archivo: Archivo, values?: any) => Promise<Preview<any>>;
  update?: (archivo?: Archivo) => Promise<Preview<any>>;
  cincelFirma?: (archivo?: Archivo) => Promise<Preview<any>>;

  // Estilo de botones, sm para mostrar solo botones pequeños
  sm?: boolean;
}

interface State {}

/**
 * Clase que renderiza un tipo de control de archivo, dependiendo de su estilo.
 */
export default class ArchivoControl extends React.Component<Props, State> {
  render() {
    return this.props.sm ? (
      <ArchivoControlSm
        archivo={this.props.archivo}
        userId={this.props.userId}
        botonCorreo={this.props.botonCorreo}
        botonFirmaOEscanner={this.props.botonFirmaOEscanner}
        botonVer={this.props.botonVer}
        botonRegenrar={this.props.botonRegenrar}
        sendEmail={this.props.sendEmail}
        regenerar={this.props.regenerar}
        formConfigRegenerar={this.props.formConfigRegenerar}
        formConfigInit={this.props.formConfigInit}
        update={this.props.update}
        cincelFirma={this.props.cincelFirma}
      />
    ) : (
      <ArchivoControlLg
        archivo={this.props.archivo}
        userId={this.props.userId}
        botonCorreo={this.props.botonCorreo}
        botonFirmaOEscanner={this.props.botonFirmaOEscanner}
        botonVer={this.props.botonVer}
        botonRegenrar={this.props.botonRegenrar}
        sendEmail={this.props.sendEmail}
        regenerar={this.props.regenerar}
        formConfigRegenerar={this.props.formConfigRegenerar}
        formConfigInit={this.props.formConfigInit}
        update={this.props.update}
        cincelFirma={this.props.cincelFirma}
      />
    );
  }
}
