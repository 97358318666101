export const hiddenForEstatus = formValue =>
  formValue.tipoBusqueda === "FACTURA";

export const hiddenForClub = formValue =>
  formValue.tipoBusqueda === "FACTURA" || formValue.tipoBusqueda === "EMPLEADO";

export const hiddenForProducto = formValue =>
  formValue.tipoBusqueda === "FACTURA" || formValue.tipoBusqueda === "EMPLEADO";

export const hiddenIfNotBusquedaFecha = formValue =>
  formValue.tipoBusqueda !== "FECHA";

export const hiddenIfNotBusquedaPeriodo = formValue =>
  formValue.tipoBusqueda !== "PERIODO";

export const hiddenIfNotBusquedaEmpleado = formValue =>
  formValue.tipoBusqueda !== "EMPLEADO";

export const hiddenIfNotBusquedaFactura = formValue =>
  formValue.tipoBusqueda !== "FACTURA";

export const requiredIfBusquedaProducto = {
  validator: (value, formValue) => formValue.tipoBusqueda === "" && !value,
  message: () => "Campo obligatorio"
};

export const requiredIfBusquedaFecha = {
  validator: (value, formValue) => formValue.tipoBusqueda === "FECHA" && !value,
  message: () => "Campo obligatorio"
};

export const requiredIfBusquedaPeriodo = {
  validator: (value, formValue) =>
    formValue.tipoBusqueda === "PERIODO" && !value,
  message: () => "Campo obligatorio"
};

export const requiredIfBusquedaEmpleado = {
  validator: (value, formValue) =>
    formValue.tipoBusqueda === "EMPLEADO" && !value,
  message: () => "Campo obligatorio"
};

export const requiredIfBusquedaFactura = {
  validator: (value, formValue) =>
    formValue.tipoBusqueda === "FACTURA" && !value,
  message: () => "Campo obligatorio"
};
