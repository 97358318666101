import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { Button, UncontrolledTooltip } from "reactstrap";
import s from "./styles.scss";
import InvitadoDetalle from "../../../types/InvitadoDetalle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faCameraRetro,
  faEye,
  faFileExport,
  faFingerprint,
  faInfoCircle,
  faPencilAlt,
  faPlus,
  faTrash,
  faUserCheck,
  faUserLock
} from "@fortawesome/free-solid-svg-icons";
import {
  InvitadoEstatus,
  InvitadoIntercambioComercialTipo,
  InvitadoTipo
} from "../../../types/Invitado";
import permissionCodes from "../../../utils/permissionCodes";
import { userHasPermission } from "../../../utils/accessTree";

const {
  INVITADO_ACTIVAR: ACTIVAR,
  INVITADO_INACTIVAR: INACTIVAR,
  INVITADO_ELIMINAR: ELIMINAR,
  INVITADO_CREAR: CREAR,
  INVITADO_EDITAR: EDITAR
} = permissionCodes;

export interface TableProps {
  page: Page<InvitadoDetalle>;
  loading: boolean;
  onFetchData: ({ page, pageSize }) => void;
  onExport: () => void;
  onAddClick: () => void;
  onEditClick: (idInvitado) => void;
  onDeleteClick: (idInvitado) => void;
  onActivacion: (idInvitado) => void;
  onDesactivacion: (idInvitado) => void;
  onHuellaClick: (idInvitado) => void;
  onFotoClick: (idInvitado) => void;
  onViewPersonaClick: (idPersona) => void;
}

export const MemoTable = React.memo(
  ({
    page,
    loading,
    onFetchData,
    onExport,
    onAddClick,
    onEditClick,
    onDeleteClick,
    onActivacion,
    onDesactivacion,
    onHuellaClick,
    onFotoClick,
    onViewPersonaClick
  }: TableProps) => {
    // State de las columnas de la tabla
    const [columns, setColumns] = useState(
      columnsFormatter(
        onAddClick,
        onEditClick,
        onDeleteClick,
        onActivacion,
        onDesactivacion,
        onHuellaClick,
        onFotoClick,
        onViewPersonaClick
      )
    );

    const [pageNumber, setPageNumber] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);

    // Actualización de estilos
    useEffect(() => {
      setColumns(
        columnsFormatter(
          onAddClick,
          onEditClick,
          onDeleteClick,
          onActivacion,
          onDesactivacion,
          onHuellaClick,
          onFotoClick,
          onViewPersonaClick
        )
      );
    }, []);

    // Actualización de datos de paginación
    useEffect(() => {
      if (page) {
        setPageNumber(page.number);
        setTotalPages(page.totalPages);
      }
    }, [page]);

    const mostrados = page && page.numberOfElements;
    const totales = page && page.totalElements;
    return (
      <>
        {/* Botón para exportar */}
        <div className="row">
          {!!page && !!page.content && !!page.content.length && (
            <>
              <div className="col-6 p-0" style={{ lineHeight: "40px" }}>
                <span>
                  Invitados mostrados: {mostrados} de {totales}
                </span>
              </div>
              <div
                className="col-6 p-0 text-right"
                style={{ lineHeight: "40px" }}
              >
                <Button
                  color="primary"
                  onClick={onExport}
                  disabled={loading}
                  className="mr-2"
                >
                  <FontAwesomeIcon icon={faFileExport} />
                  {!loading ? " Exportar" : " Espere..."}
                </Button>
              </div>
            </>
          )}
        </div>

        {/* Tabla */}
        <div className="row mt-2">
          <div style={{ width: "100%" }}>
            <ReactTable
              manual
              data={(page && page.content) || []}
              columns={columns}
              loading={loading}
              defaultPageSize={20}
              className="-striped -highlight"
              onFetchData={onFetchData}
              page={pageNumber}
              pages={totalPages}
              showPagination={true}
              showPageSizeOptions={false}
              getTrProps={() => ({ style: { height: "30px" } })}
              sortable={false}
            />
          </div>
        </div>
      </>
    );
  }
);

// TODO: Definir permisos para los botones
function columnsFormatter(
  onAddClick: () => void,
  onEditClick: (idInvitado) => void,
  onDeleteClick: (idInvitado) => void,
  onActivacion: (idInvitado) => void,
  onDesactivacion: (idInvitado) => void,
  onHuellaClick: (idPersona) => void,
  onFotoClick: (idPersona) => void,
  onViewPersonaClick: (idPersona) => void
) {
  return [
    {
      Header: "ID",
      id: "idPersona",
      className: s.tdClear,
      accessor: simpleAccessor("idPersona")
    },
    {
      Header: "Nombre",
      id: "nombreCompleto",
      className: s.tdClear,
      accessor: simpleAccessor("nombreCompleto")
    },
    {
      Header: "Tipo",
      id: "tipo",
      className: s.tdClear,
      accessor: enumAccessor(InvitadoTipo, "tipo")
    },
    {
      Header: "Estatus",
      id: "estatus",
      className: s.tdClear,
      accessor: enumAccessor(InvitadoEstatus, "estatus")
    },
    {
      Header: "Colaborador",
      id: "userColaborador",
      className: s.tdClear,
      accessor: simpleAccessor("userColaborador")
    },
    {
      Header: "Empresa",
      id: "empresa",
      className: s.tdClear,
      accessor: simpleAccessor("empresa")
    },
    {
      Header: "Tipo de Int. Com.",
      id: "tipoIntercambio",
      className: s.tdClear,
      accessor: enumAccessor(
        InvitadoIntercambioComercialTipo,
        "tipoIntercambio"
      )
    },
    {
      Header: () =>
        userHasPermission(CREAR) ? (
          <div className="w-100 text-center">
            <span id="invitadoAddId" className={s.btn} onClick={onAddClick}>
              <FontAwesomeIcon icon={faPlus} />
              <UncontrolledTooltip placement="left" target="invitadoAddId">
                Crear invitado
              </UncontrolledTooltip>
            </span>
          </div>
        ) : (
          " "
        ),
      id: "acciones",
      className: s.tdClear,
      width: 250,
      accessor: (row: InvitadoDetalle) => {
        const idPrefijo = "id-" + row.idInvitado;
        const idAcceso = idPrefijo + "-acceso";
        const idActivacion = idPrefijo + "-activacion";
        const idHuella = idPrefijo + "-huella";
        const idFoto = idPrefijo + "-foto";
        const idVerPersona = idPrefijo + "-ver-persona";
        const idEditar = idPrefijo + "-editar";
        const idEliminar = idPrefijo + "-eliminar";

        return (
          <div className={`${fieldClassName(row)} text-center`}>
            {row.tieneAcceso ? (
              <span className={s.info} id={idAcceso}>
                <FontAwesomeIcon icon={faInfoCircle} color="#008080" />
                <UncontrolledTooltip placement="left" target={idAcceso}>
                  El invitado tiene acceso
                </UncontrolledTooltip>
              </span>
            ) : (
              <span className={s.info} id={idAcceso}>
                <FontAwesomeIcon icon={faInfoCircle} color="#600" />
                <UncontrolledTooltip placement="left" target={idAcceso}>
                  {row.razonesSinAcceso.map(r => (
                    <>
                      {r}
                      <br />
                    </>
                  ))}
                </UncontrolledTooltip>
              </span>
            )}
            {row.estatus !== "ACTIVO" && userHasPermission(ACTIVAR) && (
              <span
                onClick={() => onActivacion(row.idInvitado)}
                className={s.btn}
                id={idActivacion}
              >
                <FontAwesomeIcon icon={faUserLock} color="#600" />
                <UncontrolledTooltip placement="left" target={idActivacion}>
                  Autorizar
                </UncontrolledTooltip>
              </span>
            )}
            {row.estatus === "ACTIVO" && userHasPermission(INACTIVAR) && (
              <span
                onClick={() => onDesactivacion(row.idInvitado)}
                className={s.btn}
                id={idActivacion}
              >
                <FontAwesomeIcon icon={faUserCheck} color="#060" />
                <UncontrolledTooltip placement="left" target={idActivacion}>
                  Desactivar
                </UncontrolledTooltip>
              </span>
            )}
            {row.tieneAcceso ? (
              <span
                onClick={() => onHuellaClick(row.idPersona)}
                className={s.btn}
                id={idHuella}
              >
                <FontAwesomeIcon icon={faFingerprint} color="#c90" />
                <UncontrolledTooltip placement="left" target={idHuella}>
                  Captura de huella
                </UncontrolledTooltip>
              </span>
            ) : (
              <span className={s.info} id={idHuella}>
                <FontAwesomeIcon icon={faFingerprint} color="#444" />
                <UncontrolledTooltip placement="left" target={idHuella}>
                  No puede tomar la huella
                </UncontrolledTooltip>
              </span>
            )}
            {
              <span
                onClick={() => onFotoClick(row.idPersona)}
                className={s.btn}
                id={idFoto}
              >
                <FontAwesomeIcon icon={faCameraRetro} color="#c90" />
                <UncontrolledTooltip placement="left" target={idFoto}>
                  Captura de foto
                </UncontrolledTooltip>
              </span>
            }
            {
              <span
                onClick={() => onViewPersonaClick(row.idPersona)}
                className={s.btn}
                id={idVerPersona}
              >
                <FontAwesomeIcon icon={faEye} color="#c90" />
                <UncontrolledTooltip placement="left" target={idVerPersona}>
                  Ver persona
                </UncontrolledTooltip>
              </span>
            }
            {userHasPermission(EDITAR) && (
              <span
                onClick={() => onEditClick(row.idInvitado)}
                className={s.btn}
                id={idEditar}
              >
                <FontAwesomeIcon icon={faPencilAlt} color="#c90" />
                <UncontrolledTooltip placement="left" target={idEditar}>
                  Editar invitado
                </UncontrolledTooltip>
              </span>
            )}
            {userHasPermission(ELIMINAR) && (
              <span
                onClick={() => onDeleteClick(row.idInvitado)}
                className={s.btn}
                id={idEliminar}
              >
                <FontAwesomeIcon icon={faTrash} color="#600" />
                <UncontrolledTooltip placement="left" target={idEliminar}>
                  Eliminar invitado
                </UncontrolledTooltip>
              </span>
            )}
          </div>
        );
      }
    }
  ];
}

function simpleAccessor(key: keyof InvitadoDetalle) {
  return (row: InvitadoDetalle) => {
    return <div className={fieldClassName(row)}>{row[key]}&nbsp;</div>;
  };
}

function enumAccessor(enumerated: any, key: keyof InvitadoDetalle) {
  return (row: InvitadoDetalle) => {
    return (
      <div className={fieldClassName(row)}>
        {enumerated[row[key] as string]}&nbsp;
      </div>
    );
  };
}

function fieldClassName(row: InvitadoDetalle) {
  // Fondo rojo si el invitado no tiene acceso a club
  return row.tieneAcceso ? `${s.field}` : `${s.field} ${s.sinAcceso}`;
}
