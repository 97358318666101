import {
  faCashRegister,
  faEnvelope,
  faFileAlt,
  faFileCsv,
  faFilePdf,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import moment from "moment";
import React from "react";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import { Button, Col, Container, Form, Row } from "reactstrap";
import BaseButtonWithIconAndTooltip from "../../../../toolkit/baseButtonWithIconAndTooltip";
import BaseInput from "../../../../toolkit/baseInput";
import ModalFrame from "../../../../toolkit/modalFrame";
import IAction from "../../../../types/IAction";
import { userHasPermission } from "../../../../utils/accessTree";
import constants, {
  OPTIONS_FORMA_PAGO,
  PAYMENT_METHODS
} from "../../../../utils/constants";
import { handleRequestError } from "../../../../utils/helpers";
import {
  errorNotification,
  infoNotification,
  successNotification
} from "../../../../utils/notifications";
import permissionCodes from "../../../../utils/permissionCodes";
import {
  openBillXml,
  openBillXmlAddendas,
  postContabilizar,
  sendFinanceInvoiceByEmailRequest
} from "../../../actions/billing";
import {
  downloadReportAccountingAccountFileId,
  downloadReportWayToPayFileId,
  searchReportGeneralCobranza,
  downloadReportGeneralCobranzaFileId
} from "../../../actions/reports";
import MexicanCurrency from "../../common/currency";
import DatePicker from "../../common/DatePicker";
import CancelacionGenModal from "../cancelacionGenModal";
import CreditNoteGenerationModal from "../creditNoteGenModal/index";
import EmailBill from "../EmailBill";
import s from "./styles.scss";

// Permiso de solicitud de nota de crédito
const { SOLICITAR_NOTA_CREDITO } = permissionCodes;

interface Props {
  sendMovementEmail: (id: number, email: string) => any;
  toggleBill: (storageId: number) => any;
  getAccountingAccount: (fields: object) => IAction;
  getWayToPay: (fields: object) => IAction;
  downloadReportAccountingAccount: (body: object, callback?: any) => IAction;
  downloadReportWayToPay: (body: object, callback?: any) => IAction;
  clubs: {
    clubId: number;
    nombre: string;
  }[];
  tipoReporte: {
    tipoReporteId: number;
    tipoReporte: string;
  }[];
  accountingAccounts: {
    cuentaContableId: string;
    nombre: string;
    cuentaContable: string;
  }[];
  reportAccountingAccount: object[];
  reportWayToPay: object[];
  getBill: (uuid: string) => any;
  billing: object[];
  createCreditNote: (fields: object) => IAction;
  banks: object;
  userContext: { clubSessionId: any };
}

interface State {
  filters: object;
  idModalEmail: number;
  modalEmail: boolean;
  clubDispersion: boolean;
  onSearch: boolean;
  isOpen: boolean;
  selCreditNoteGeneration: boolean;
  selCancelacionGeneration: boolean;
  timbradoUUID: string;
  loading: boolean;
  downloading: boolean;
  contabilizando: boolean;
  cobranzaGeneralList: any[];
}

const START_DATE_FIELDNAME = "fechaInicio";
const END_DATE_FIELDNAME = "fechaFin";

class BillSearch extends React.Component<Props, State> {
  state = {
    filters: {
      idClub: null,
      cuentaContable: "",
      idReporte: "",
      estatus: "",
      fechaInicio: null,
      fechaFin: null,
      formaDePago: "",
      metodoPago: "",
      folioReferencia: ""
    },
    isOpen: false,
    clubDispersion: false,
    idModalEmail: null,
    modalEmail: false,
    onSearch: false,
    catalogoEstatus: [
      {
        estatusId: "CREADA",
        estatus: "CREADA"
      },
      {
        estatusId: "TIMBRADA",
        estatus: "TIMBRADA"
      },
      {
        estatusId: "ENVIADA_ERP",
        estatus: "ENVIADA_ERP"
      },
      {
        estatusId: "CANCELADA",
        estatus: "CANCELADA"
      }
    ],
    selCreditNoteGeneration: false,
    timbradoUUID: null,
    selCancelacionGeneration: false,
    loading: false,
    downloading: false,
    contabilizando: false,
    cobranzaGeneralList: []
  };

  componentDidMount() {
    const { filters } = this.state;
    filters.idClub = this.props.userContext.clubSessionName;
    this.setState({ filters: { ...filters } });
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (
      this.state.loading &&
      (prevProps.reportAccountingAccount !=
        this.props.reportAccountingAccount ||
        prevProps.reportWayToPay != this.props.reportWayToPay)
    ) {
      this.setState({
        downloading: false,
        loading: false
      });
    }
  }

  toggleModalEmail = idModalEmail => {
    this.setState({ idModalEmail, modalEmail: !this.state.modalEmail });
  };

  columns = [
    {
      Header: () => <p className="font-weight-bold">Folio</p>,
      id: 0,
      width: 80,
      accessor: "folio",
      Cell: row => {
        return <p className={s.initial}>{row.original.folio}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Club factura</p>,
      id: 0,
      accessor: "clubVenta",
      Cell: row => {
        return <p className={s.initial}>{row.original.clubVenta}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Club dispersión</p>,
      id: 0,
      accessor: "clubDispersion",
      Cell: row => {
        return <p className={s.initial}>{row.original.clubDispersion || ""}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Club membresía</p>,
      id: 0,
      width: 120,
      accessor: "clubMembresia",
      Cell: row => {
        return <p className={s.initial}>{row.original.clubMembresia}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold"># Membresia</p>,
      width: 100,
      id: 0,
      accessor: "membresiaSocioId",
      Cell: row => {
        return <p className={s.initial}>{row.original.membresiaSocioId}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Fecha factura</p>,
      id: 0,
      accessor: "fechaFacturacion",
      Cell: row => {
        return <p className={s.initial}>{row.original.fechaFacturacion}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">RFC</p>,
      id: 0,
      width: 120,
      accessor: "rfc",
      Cell: row => {
        return <p className={s.initial}>{row.original.rfc}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Razón social</p>,
      width: 160,
      id: 0,
      accessor: "razonSocial",
      Cell: row => {
        return <p className={s.initial}>{row.original.razonSocial}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Importe</p>,
      id: 0,
      width: 120,
      accessor: "montoFactura",
      Cell: row => {
        return (
          <p className={s.initial}>
            <MexicanCurrency quantity={row.original.montoFactura} />
          </p>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Estatus</p>,
      id: 0,
      accessor: "estatus",
      Cell: row => {
        return <p className={s.initial}>{row.original.estatus}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Tipo venta</p>,
      id: 0,
      width: 150,
      accessor: "tipoVenta",
      Cell: row => {
        return <p className={s.initial}>{row.original.tipoVenta}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Forma pago</p>,
      id: 0,
      accessor: "itemFiltroCrm",
      Cell: row => {
        return <p className={s.initial}>{row.original.itemFiltroCrm || ""}</p>;
      }
    },
    {
      Header: () => (
        <p className="font-weight-bold">
          Importe forma
          <br />
          de pago
        </p>
      ),
      id: 0,
      width: 120,
      accessor: "itemMonto",
      Cell: row => {
        return (
          <p className={s.initial}>
            <MexicanCurrency quantity={row.original.itemMonto || 0} />
          </p>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Referencia</p>,
      id: 0,
      width: 160,
      accessor: "itemReferencia",
      Cell: row => {
        return <p className={s.initial}>{row.original.itemReferencia || ""}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">UUID</p>,
      id: 0,
      width: 180,
      accessor: "timbradoUUID",
      Cell: row => {
        return <p className={s.initial}>{row.original.timbradoUUID}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Responsable</p>,
      id: 0,
      width: 180,
      accessor: "ventaResponsaBle",
      Cell: row => {
        return <p className={s.initial}>{row.original.ventaResponsable}</p>;
      }
    },
    {
      Header: () => {
        return <p className="font-weight-bold">Opciones</p>;
      },
      id: -300,
      width: 150,
      accessor: "actions",
      Cell: row => {
        return this.state.contabilizando ? (
          "Espere..."
        ) : (
          <Container className="p-0">
            <Row className="m-0">
              <Col>
                <BaseButtonWithIconAndTooltip
                  id={`email${row.original.facturaId}`}
                  icon={faEnvelope}
                  size="sm"
                  tooltipTitle="Enviar a email"
                  onClick={() => {
                    this.toggleModalEmail(row.original.timbradoUUID);
                  }}
                />

                <BaseButtonWithIconAndTooltip
                  id={`pdf${row.original.timbradoPdfStorageId}`}
                  icon={faFilePdf}
                  size="sm"
                  tooltipTitle="Abrir Pdf"
                  onClick={() => {
                    this.props.toggleBill(row.original.timbradoPdfStorageId);
                  }}
                />

                <BaseButtonWithIconAndTooltip
                  id={`xml${row.original.facturaId}`}
                  icon={faFileAlt}
                  size="sm"
                  tooltipTitle="Abrir xml"
                  onClick={() => {
                    openBillXml(row.original.timbradoUUID);
                  }}
                />

                <BaseButtonWithIconAndTooltip
                  id={`xmladdendas${row.original.facturaId}`}
                  icon={faFileCsv}
                  size="sm"
                  tooltipTitle="Abrir xml de addendas"
                  onClick={() => {
                    openBillXmlAddendas(row.original.timbradoUUID);
                  }}
                />

                {row.original &&
                row.original.timbradoUUID &&
                userHasPermission(SOLICITAR_NOTA_CREDITO) ? (
                  <BaseButtonWithIconAndTooltip
                    id={`complemento${row.original.timbradoUUID}`}
                    icon={faCashRegister}
                    size="sm"
                    tooltipTitle=" Nota de crédito"
                    onClick={() => {
                      this.props.getBill(row.original.timbradoUUID);
                      this.setState({
                        selCreditNoteGeneration: true,
                        timbradoUUID: row.original.timbradoUUID
                      });
                    }}
                  />
                ) : null}

                {row.original && row.original.timbradoUUID ? (
                  <BaseButtonWithIconAndTooltip
                    id={`cancelacion${row.original.timbradoUUID}`}
                    icon={faTimesCircle}
                    color="#a00"
                    size="sm"
                    tooltipTitle="Cancelación"
                    onClick={async () => {
                      if (row.original.estatus == "TIMBRADA") {
                        try {
                          infoNotification(
                            "Contabilizando la factura, espere un momento por favor"
                          );
                          this.setState({ contabilizando: true });

                          await postContabilizar(row.original.facturaId);
                          this.setState({ contabilizando: false });

                          successNotification(
                            "La factura ha sido contabilizada, proceda con la cancelación"
                          );
                          row.original.estatus = "ENVIADA_ERP";
                        } catch (_) {
                          errorNotification(
                            "No fue posible contabilizar la factura; intente de nuevo más tarde"
                          );
                          this.setState({ contabilizando: false });
                          return;
                        }
                      } else if (row.original.estatus != "ENVIADA_ERP") {
                        errorNotification(
                          "No puede cancelar una factura sin contabilizarla; intente de nuevo más tarde"
                        );
                        return;
                      }

                      this.props.getBill(row.original.timbradoUUID);
                      this.setState({
                        selCancelacionGeneration: true,
                        timbradoUUID: row.original.timbradoUUID
                      });
                    }}
                  />
                ) : null}
              </Col>
            </Row>
          </Container>
        );
      }
    }
  ];

  columnsCuentaContable = [
    {
      Header: () => <p className="font-weight-bold">Fecha factura</p>,
      id: 0,
      accessor: "fechaFacturacion",
      Cell: row => {
        return <p className={s.initial}>{row.original.fechaFacturacion}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Fecha aplica</p>,
      id: 0,
      accessor: "itemFechaAplica",
      Cell: row => {
        return <p className={s.initial}>{row.original.itemFechaAplica}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Razón social</p>,
      width: 160,
      id: 0,
      accessor: "razonSocial",
      Cell: row => {
        return <p className={s.initial}>{row.original.razonSocial}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">RFC</p>,
      id: 0,
      width: 120,
      accessor: "rfc",
      Cell: row => {
        return <p className={s.initial}>{row.original.rfc}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Club factura</p>,
      id: 0,
      accessor: "clubFactura",
      Cell: row => {
        return <p className={s.initial}>{row.original.clubFactura}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Club dispersión</p>,
      id: 0,
      width: 120,
      accessor: "clubDispersion",
      Cell: row => {
        return <p className={s.initial}>{row.original.clubDispersion || ""}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Club membresía</p>,
      id: 0,
      width: 120,
      accessor: "clubMembresia",
      Cell: row => {
        return <p className={s.initial}>{row.original.clubMembresia}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold"># Membresia</p>,
      width: 100,
      id: 0,
      accessor: "membresiaSocioId",
      Cell: row => {
        return <p className={s.initial}>{row.original.membresiaSocioId}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Descripción</p>,
      id: 0,
      width: 180,
      accessor: "itemNombre",
      Cell: row => {
        return <p className={s.initial}>{row.original.itemNombre}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Tipo</p>,
      id: 0,
      width: 150,
      accessor: "itemTipo",
      Cell: row => {
        return <p className={s.initial}>{row.original.itemTipo}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Factura</p>,
      id: 0,
      width: 80,
      accessor: "facturaFolio",
      Cell: row => {
        return <p className={s.initial}>{row.original.facturaFolio}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Estatus</p>,
      id: 0,
      accessor: "facturaEstatus",
      Cell: row => {
        return <p className={s.initial}>{row.original.facturaEstatus}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Total factura</p>,
      id: 0,
      width: 120,
      accessor: "facturaImporte",
      Cell: row => {
        return (
          <p className={s.initial}>
            <MexicanCurrency quantity={row.original.facturaImporte || 0} />
          </p>
        );
      }
    },

    {
      Header: () => (
        <p className="font-weight-bold">
          # Cuenta
          <br />
          contable
        </p>
      ),
      id: 0,
      width: 100,
      accessor: "itemCuentaContableClave",
      Cell: row => {
        return (
          <p className={s.initial}>{row.original.itemCuentaContableClave}</p>
        );
      }
    },
    {
      Header: () => (
        <p className="font-weight-bold">
          Desc. Cuenta
          <br />
          contable
        </p>
      ),
      id: 0,
      width: 140,
      accessor: "itemCuentaContableNombre",
      Cell: row => {
        return (
          <p className={s.initial}>{row.original.itemCuentaContableNombre}</p>
        );
      }
    },

    {
      Header: () => <p className="font-weight-bold">Importe</p>,
      id: 0,
      width: 120,
      accessor: "itemMontoTotal",
      Cell: row => {
        return (
          <p className={s.initial}>
            <MexicanCurrency quantity={row.original.itemMontoTotal || 0} />
          </p>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Importe sin Iva</p>,
      id: 0,
      width: 120,
      accessor: "itemMontoSinIva",
      Cell: row => {
        return (
          <p className={s.initial}>
            <MexicanCurrency quantity={row.original.itemMontoSinIva || 0} />
          </p>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Importe Iva</p>,
      id: 0,
      width: 120,
      accessor: "itemMontoIva",
      Cell: row => {
        return (
          <p className={s.initial}>
            <MexicanCurrency quantity={row.original.itemMontoIva || 0} />
          </p>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Origen</p>,
      id: 0,
      accessor: "itemOrigen",
      Cell: row => {
        return <p className={s.initial}>{row.original.itemOrigen || ""}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Referencia</p>,
      id: 0,
      width: 160,
      accessor: "referencias",
      Cell: row => {
        return <p className={s.initial}>{row.original.referencias || ""}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">UUID</p>,
      id: 0,
      width: 180,
      accessor: "facturaUUID",
      Cell: row => {
        return <p className={s.initial}>{row.original.facturaUUID}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Fecha inicio</p>,
      id: 0,
      accessor: "itemFechaInicio",
      Cell: row => {
        return <p className={s.initial}>{row.original.itemFechaInicio}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Fecha fin</p>,
      id: 0,
      accessor: "itemFechaFin",
      Cell: row => {
        return <p className={s.initial}>{row.original.itemFechaFin}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Cuenta producto</p>,
      id: 0,
      width: 120,
      accessor: "itemCuentaProducto",
      Cell: row => {
        return <p className={s.initial}>{row.original.itemCuentaProducto}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Responsable</p>,
      id: 0,
      width: 180,
      accessor: "ventaResponsaBle",
      Cell: row => {
        return <p className={s.initial}>{row.original.ventaResponsable}</p>;
      }
    },
    {
      Header: () => {
        return <p className="font-weight-bold">Opciones</p>;
      },
      id: -300,
      width: 150,
      accessor: "actions",
      Cell: row => {
        return this.state.contabilizando ? (
          "Espere..."
        ) : (
          <Container className="p-0">
            <Row className="m-0">
              <Col>
                <BaseButtonWithIconAndTooltip
                  id={`email${row.original.facturaId}-${row.original.ventaId}`}
                  icon={faEnvelope}
                  size="sm"
                  tooltipTitle="Enviar a email"
                  onClick={() =>
                    this.toggleModalEmail(row.original.facturaUUID)
                  }
                />
                <BaseButtonWithIconAndTooltip
                  id={`factura${row.original.facturaPdfStorageId}`}
                  icon={faFilePdf}
                  size="sm"
                  tooltipTitle="Abrir Pdf"
                  onClick={() =>
                    this.props.toggleBill(row.original.facturaPdfStorageId)
                  }
                />

                <BaseButtonWithIconAndTooltip
                  id={`xml${row.original.facturaId}`}
                  icon={faFileAlt}
                  size="sm"
                  tooltipTitle="Abrir xml"
                  onClick={() => {
                    openBillXml(row.original.facturaUUID);
                  }}
                />

                <BaseButtonWithIconAndTooltip
                  id={`xmladdendas${row.original.facturaId}`}
                  icon={faFileCsv}
                  size="sm"
                  tooltipTitle="Abrir xml de addendas"
                  onClick={() => {
                    openBillXmlAddendas(row.original.facturaUUID);
                  }}
                />

                {row.original &&
                row.original.facturaUUID &&
                userHasPermission(SOLICITAR_NOTA_CREDITO) ? (
                  <BaseButtonWithIconAndTooltip
                    id={`complemento${row.original.facturaUUID}`}
                    icon={faCashRegister}
                    size="sm"
                    tooltipTitle="Nota de crédito"
                    onClick={() => {
                      this.props.getBill(row.original.facturaUUID);
                      this.setState({
                        selCreditNoteGeneration: true,
                        timbradoUUID: row.original.facturaUUID
                      });
                    }}
                  />
                ) : null}

                {row.original && row.original.facturaUUID ? (
                  <BaseButtonWithIconAndTooltip
                    id={`cancelacion${row.original.facturaUUID}`}
                    icon={faTimesCircle}
                    color="#a00"
                    size="sm"
                    tooltipTitle="Cancelación"
                    onClick={async () => {
                      if (row.original.facturaEstatus == "TIMBRADA") {
                        try {
                          infoNotification(
                            "Contabilizando la factura, espere un momento por favor"
                          );
                          this.setState({ contabilizando: true });

                          await postContabilizar(row.original.facturaId);
                          this.setState({ contabilizando: false });

                          successNotification(
                            "La factura ha sido contabilizada, proceda con la cancelación"
                          );
                          row.original.facturaEstatus = "ENVIADA_ERP";
                        } catch (_) {
                          errorNotification(
                            "No fue posible contabilizar la factura; intente de nuevo más tarde"
                          );
                          this.setState({ contabilizando: false });
                          return;
                        }
                      } else if (row.original.facturaEstatus != "ENVIADA_ERP") {
                        errorNotification(
                          "No puede cancelar una factura sin contabilizarla; intente de nuevo más tarde"
                        );
                        return;
                      }

                      this.props.getBill(row.original.facturaUUID);
                      this.setState({
                        selCancelacionGeneration: true,
                        timbradoUUID: row.original.facturaUUID
                      });
                    }}
                  />
                ) : null}
              </Col>
            </Row>
          </Container>
        );
      }
    }
  ];

  onChangeDate = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const filters = {
          ...state.filters,
          [fieldName]: date
        };
        return { ...state, error: {}, filters };
      });
    };
  };

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  onChangeCheck = e => {
    this.setState({
      clubDispersion: e.target.checked
    });
  };

  onClickDownloadCsvFile = () => {
    const {
      idReporte,
      fechaInicio,
      fechaFin,
      idClub,
      estatus,
      cuentaContable,
      formaDePago,
      folioReferencia
    } = this.state.filters;
    const { clubDispersion } = this.state;

    this.setState({
      downloading: true
    });

    infoNotification("Descargando documento");

    if (idReporte === "CuentaContable") {
      const filters = {
        club: idClub,
        clubDispersion,
        estatus,
        fechaInicio,
        fechaFin,
        cuentaContable,
        folioReferencia
      };

      downloadReportAccountingAccountFileId(filters)
        .then(({ data }) => {
          this.setState({
            downloading: false,
            loading: false
          });

          const url = `${constants.BASE_URL}/${constants.DOCUMENTS_BASE_URL}/${data}`;
          window.open(url, "_blank");
        })
        .catch(err => {
          this.setState({
            downloading: false,
            loading: false
          });

          const msg = "No se pudo descargar el archivo";
          if (err && err.response && err.response.data) {
            try {
              const message = JSON.parse(err.response.data).message || msg;
              errorNotification(message);
            } catch (erj) {
              errorNotification(msg);
            }
          } else {
            errorNotification(msg);
          }
        });
    }

    if (idReporte === "FormaPago") {
      const filters = {
        club: idClub,
        clubDispersion,
        estatus,
        fechaInicio,
        fechaFin,
        formaDePago
      };

      downloadReportWayToPayFileId(filters)
        .then(({ data }) => {
          this.setState({
            downloading: false,
            loading: false
          });

          const url = `${constants.BASE_URL}/${constants.DOCUMENTS_BASE_URL}/${data}`;
          window.open(url, "_blank");
        })
        .catch(err => {
          this.setState({
            downloading: false,
            loading: false
          });

          const msg = "No se pudo descargar el archivo";
          if (err && err.response && err.response.data) {
            try {
              const message = JSON.parse(err.response.data).message || msg;
              errorNotification(message);
            } catch (erj) {
              errorNotification(msg);
            }
          } else {
            errorNotification(msg);
          }
        });
    }

    if (idReporte === "CobranzaGeneral") {
      const filters = {
        club: idClub,
        fechaInicio,
        fechaFin
      };

      downloadReportGeneralCobranzaFileId(filters)
        .then(({ data }) => {
          this.setState({
            downloading: false,
            loading: false
          });

          const url = `${constants.BASE_URL}/${constants.DOCUMENTS_BASE_URL}/${data}`;
          window.open(url, "_blank");
        })
        .catch(err => {
          this.setState({
            downloading: false,
            loading: false
          });

          const msg = "No se pudo descargar el archivo";
          if (err && err.response && err.response.data) {
            try {
              const message = JSON.parse(err.response.data).message || msg;
              errorNotification(message);
            } catch (erj) {
              errorNotification(msg);
            }
          } else {
            errorNotification(msg);
          }
        });
    }
  };

  search = () => {
    this.setState({
      onSearch: true,
      loading: true
    });

    const {
      idReporte,
      fechaInicio,
      fechaFin,
      idClub,
      cuentaContable,
      estatus,
      formaDePago,
      metodoPago,
      folioReferencia
    } = this.state.filters;
    const { clubDispersion } = this.state;

    if (idReporte === "CuentaContable") {
      this.props.getAccountingAccount({
        club: idClub,
        clubDispersion,
        fechaInicio,
        fechaFin,
        estatus,
        cuentaContable,
        metodoPago: metodoPago.length == 0 ? null : metodoPago,
        folioReferencia
      });
    }

    if (idReporte === "FormaPago") {
      this.props.getWayToPay({
        club: idClub,
        clubDispersion,
        fechaInicio,
        fechaFin,
        estatus,
        formaDePago,
        metodoPago: metodoPago.length == 0 ? null : metodoPago,
        folioReferencia
      });
    }

    if (idReporte === "CobranzaGeneral") {
      this.setState({ loading: true, onSearch: true });
      searchReportGeneralCobranza({
        club: idClub,
        fechaInicio,
        fechaFin
      })
        .then(({ data }) => {
          this.setState({
            loading: false,
            onSearch: false,
            cobranzaGeneralList: data
          });
        })
        .catch(() => {
          errorNotification("Error al consultar el reporte.");
          this.setState({
            loading: false,
            onSearch: false,
            cobranzaGeneralList: []
          });
        });
    }
  };

  sendByEmail = (uuid: String, email: String) => {
    sendFinanceInvoiceByEmailRequest(uuid, email)
      .then(() => {
        successNotification("Factura enviada correctamente.");
        this.toggleModalEmail(null);
      })
      .catch(handleRequestError);
  };

  render() {
    const {
      idClub,
      idReporte,
      formaDePago,
      cuentaContable,
      estatus,
      folioReferencia
    } = this.state.filters;

    const { cobranzaGeneralList } = this.state;

    const {
      clubs,
      tipoReporte,
      accountingAccounts,
      reportAccountingAccount,
      reportWayToPay
    } = this.props;

    let listAccountingAccount =
      reportAccountingAccount === null ? [] : reportAccountingAccount;
    let listWayToPay = reportWayToPay === null ? [] : reportWayToPay;

    let lista = [];
    let tableColumns = [...this.columns];

    switch (idReporte) {
      case "CuentaContable":
        lista = listAccountingAccount || [];
        break;
      case "FormaPago":
        lista = listWayToPay || [];
        break;
      case "CobranzaGeneral":
        lista = [...cobranzaGeneralList];
        break;
      default:
        lista = [];
        break;
    }

    tableColumns =
      idReporte === "CuentaContable" || idReporte === "CobranzaGeneral"
        ? [...this.columnsCuentaContable]
        : [...this.columns];

    return (
      <Container className="p-0">
        <CreditNoteGenerationModal
          selPostOpen={this.state.selCreditNoteGeneration}
          toggle={() => {
            const selCreditNoteGeneration = !this.state.selCreditNoteGeneration;
            if (!selCreditNoteGeneration) {
            }
            this.setState({ selCreditNoteGeneration });
          }}
          timbradoUUID={this.state.timbradoUUID}
          createCreditNote={this.props.createCreditNote}
          billing={this.props.billing}
          banks={this.props.banks}
        />
        <CancelacionGenModal
          selPostOpen={this.state.selCancelacionGeneration}
          toggle={() => {
            const selCancelacionGeneration = !this.state
              .selCancelacionGeneration;
            this.setState({ selCancelacionGeneration });
          }}
          timbradoUUID={this.state.timbradoUUID}
          createCreditNote={this.props.createCreditNote}
          billing={this.props.billing}
          banks={this.props.banks}
        />
        <ModalFrame
          title={"Mandar Factura por Mail"}
          isOpen={this.state.modalEmail}
          toggle={this.toggleModalEmail}
          size="lg"
        >
          <EmailBill
            id={this.state.idModalEmail}
            sendEmail={this.sendByEmail}
          />
        </ModalFrame>
        <Form
          onSubmit={e => {
            e.preventDefault();
            this.search();
          }}
        >
          <Row>
            <Col xs={12} md={3} lg={3}>
              <BaseInput
                label="Club"
                name="club"
                id="club"
                type="select"
                placeholder="Seleccionar"
                value={this.state.filters.idClub}
                onChange={this.onChangeFilter("idClub")}
                size={"sm"}
                options={[{ value: "", label: "Seleccionar" }].concat(
                  clubs
                    ? clubs.map(option => ({
                        value: option.nombre,
                        label: option.nombre
                      }))
                    : []
                )}
              />
            </Col>
            {idReporte === "FormaPago" || idReporte === "CuentaContable" ? (
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  label="Estatus"
                  name="estatus"
                  id="estatus"
                  type="select"
                  placeholder="Seleccionar"
                  value={estatus}
                  onChange={this.onChangeFilter("estatus")}
                  size={"sm"}
                  options={[{ value: "", label: "Seleccionar" }].concat(
                    this.state.catalogoEstatus
                      ? this.state.catalogoEstatus.map(option => ({
                          value: option.estatusId.toString(),
                          label: option.estatus
                        }))
                      : []
                  )}
                />
              </Col>
            ) : null}
            <Col xs={12} md={2} lg={2}>
              <BaseInput
                label="Tipo Reporte"
                name="tipoReporte"
                id="tipoReporte"
                type="select"
                placeholder="Seleccionar"
                value={idReporte}
                onChange={this.onChangeFilter("idReporte")}
                size={"sm"}
                options={[{ value: "", label: "Seleccionar" }].concat(
                  tipoReporte
                    ? [
                        ...tipoReporte.map(option => ({
                          value: option.tipoReporteId.toString(),
                          label: option.tipoReporte
                        })),
                        {
                          value: "CobranzaGeneral",
                          label: "Cobranza general"
                        }
                      ]
                    : []
                )}
              />
            </Col>
            {idReporte === "FormaPago" ? (
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  label="Forma de Pago"
                  name="formaDePagoPagoProductos"
                  type="select"
                  id="formaDePagoPagoProductos"
                  placeholder="Seleccionar"
                  options={[{ value: "", label: "Seleccionar" }].concat(
                    OPTIONS_FORMA_PAGO.map(option => ({
                      value: option,
                      label:
                        option == "POR_DEFINIR_99" ? "99 - POR DEFINIR" : option
                    }))
                  )}
                  value={formaDePago}
                  onChange={this.onChangeFilter("formaDePago")}
                />
              </Col>
            ) : idReporte === "CuentaContable" ? (
              <Col xs={12} md={3} lg={3}>
                <BaseInput
                  label="Cuenta contable"
                  name="cuentaContable"
                  id="cuentaContable"
                  type="select"
                  placeholder="Seleccionar"
                  value={cuentaContable}
                  onChange={this.onChangeFilter("cuentaContable")}
                  size={"sm"}
                  options={[{ value: "", label: "Seleccionar" }].concat(
                    accountingAccounts
                      ? accountingAccounts.map(option => ({
                          value: option.numeroCuenta,
                          label: `${option.numeroCuenta}-${option.nombre}`
                        }))
                      : []
                  )}
                />
              </Col>
            ) : (
              ""
            )}
            <Col xs={12} md={2} lg={2}>
              <DatePicker
                label="Fecha Inicial"
                name={START_DATE_FIELDNAME}
                id={START_DATE_FIELDNAME}
                selected={this.state.filters[START_DATE_FIELDNAME]}
                onChange={this.onChangeDate(START_DATE_FIELDNAME)}
              />
            </Col>
            <Col xs={12} md={2} lg={2}>
              <DatePicker
                label="Fecha Final"
                name={END_DATE_FIELDNAME}
                id={END_DATE_FIELDNAME}
                selected={this.state.filters[END_DATE_FIELDNAME]}
                onChange={this.onChangeDate(END_DATE_FIELDNAME)}
              />
            </Col>

            {idReporte === "FormaPago" || idReporte === "CuentaContable" ? (
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  label="Método de pago"
                  name="metodoPago"
                  id="metodoPago"
                  type="select"
                  placeholder="Seleccionar"
                  value={null}
                  onChange={this.onChangeFilter("metodoPago")}
                  size={"sm"}
                  options={[{ value: "", label: "Seleccionar" }].concat(
                    PAYMENT_METHODS.map(option => ({
                      value: option.value,
                      label: option.label
                    }))
                  )}
                />
              </Col>
            ) : null}

            {idReporte === "FormaPago" || idReporte === "CuentaContable" ? (
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  label="Folio Referencia"
                  name="folioReferencia"
                  id="folioReferencia"
                  type="text"
                  placeholder="Folio referencia"
                  value={folioReferencia}
                  onChange={this.onChangeFilter("folioReferencia")}
                  size={"sm"}
                />
              </Col>
            ) : null}
            <Col xs={12} md={3} lg={3} className="mt-4">
              <Button
                size="sm"
                className={`float-right btn-block`}
                type="submit"
                disabled={
                  this.state.filters.idReporte === "" ||
                  this.state.filters.fechaInicio === null ||
                  this.state.filters.fechaFin === null ||
                  !this.state.filters.idClub ||
                  this.state.loading ||
                  this.state.downloading
                }
              >
                {this.state.loading ? "Buscando..." : "Buscar"}
              </Button>
            </Col>
            <Col xs={12} md={3} lg={3} className="mt-4">
              <Button
                size="sm"
                className={`float-right btn-block`}
                type="button"
                onClick={this.onClickDownloadCsvFile}
                disabled={
                  this.state.filters.idReporte === "" ||
                  this.state.filters.fechaInicio === null ||
                  this.state.filters.fechaFin === null ||
                  this.state.loading ||
                  this.state.downloading
                }
              >
                {this.state.downloading
                  ? "Descargando..."
                  : "Descargar reporte"}
              </Button>
            </Col>
          </Row>
        </Form>
        {!!(lista || []).length && (
          <Row className={"mt-5"}>
            <Col sm="6">
              Resultados de la búsqueda:{" "}
              <b
                style={{ fontWeight: "bolder", display: "inline-block" }}
                className="ml-3"
              >
                {(lista || []).length}
              </b>
            </Col>
            {/*
            Prueba de botón de descarga
            <Col sm="6" className="text-right">
              <span>Exportar a CSV: </span>
              <Button
                id="btnReportExportCsv"
                color="link"
                onClick={this.onClickDownloadCsvFile}
              >
                <FontAwesomeIcon icon={faFileExport} color="black" />
              </Button>
            </Col> */}
          </Row>
        )}
        <ReactTable
          data={lista}
          columns={tableColumns}
          className="mt-2 -striped -highlight"
          sortable={false}
          resizable={false}
          pageSize={20}
        />
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(BillSearch));
