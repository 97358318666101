import {
  GetCmsClasesAsignadas,
  GetCmsProductosAsignados,
  PutCmsClasesImparticion,
  PutCmsClasesReasignacion
} from "../../../reducers/comisionesV2";

export interface CImparticionStore
  extends GetCmsProductosAsignados,
    GetCmsClasesAsignadas,
    PutCmsClasesImparticion,
    PutCmsClasesReasignacion {}

export const cImparticionStore: (state) => CImparticionStore = state => {
  const { comisionesV2 } = state;
  return {
    cmsProductosAsignados: comisionesV2.cmsProductosAsignados,
    gettingCmsProductosAsignados: comisionesV2.gettingCmsProductosAsignados,
    gettingCmsProductosAsignadosError:
      comisionesV2.gettingCmsProductosAsignadosError,

    cmsClasesAsignadas: comisionesV2.cmsClasesAsignadas,
    gettingCmsClasesAsignadas: comisionesV2.gettingCmsClasesAsignadas,
    gettingCmsClasesAsignadasError: comisionesV2.gettingCmsClasesAsignadasError,

    puttingCmsClasesImparticion: comisionesV2.puttingCmsClasesImparticion,
    puttingCmsClasesImparticionError:
      comisionesV2.puttingCmsClasesImparticionError,

    puttingCmsClasesReasignacion: comisionesV2.puttingCmsClasesReasignacion,
    puttingCmsClasesReasignacionError:
      comisionesV2.puttingCmsClasesReasignacionError
  };
};
