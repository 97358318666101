import {
  GetCmsDeportivaPeriodo,
  GetCmsDeportivaAutorizacion,
  PutCmsDeportivaAutorizar,
  PutCmsDeportivaRechazo,
  PutCmsDeportivaReasignacion
} from "../../../reducers/comisionesV2";

export interface DAutorizacionStore
  extends GetCmsDeportivaPeriodo,
    GetCmsDeportivaAutorizacion,
    PutCmsDeportivaAutorizar,
    PutCmsDeportivaRechazo,
    PutCmsDeportivaReasignacion {}

export const dAutorizacionStore: (state) => DAutorizacionStore = state => {
  const { comisionesV2 } = state;

  return {
    cmsDeportivaPeriodo: comisionesV2.cmsDeportivaPeriodo,
    gettingCmsDeportivaPeriodo: comisionesV2.gettingCmsDeportivaPeriodo,
    gettingCmsDeportivaPeriodoError:
      comisionesV2.gettingCmsDeportivaPeriodoError,

    cmsDeportivaAutorizacion: comisionesV2.cmsDeportivaAutorizacion,
    gettingCmsDeportivaAutorizacion:
      comisionesV2.gettingCmsDeportivaAutorizacion,
    gettingCmsDeportivaAutorizacionError:
      comisionesV2.gettingCmsDeportivaAutorizacionError,

    puttingCmsDeportivaAutorizar: comisionesV2.puttingCmsDeportivaAutorizar,
    puttingCmsDeportivaAutorizarError:
      comisionesV2.puttingCmsDeportivaAutorizarError,

    puttingCmsDeportivaRechazo: comisionesV2.puttingCmsDeportivaRechazo,
    puttingCmsDeportivaRechazoError:
      comisionesV2.puttingCmsDeportivaRechazoError,

    puttingCmsDeportivaReasignacion:
      comisionesV2.puttingCmsDeportivaReasignacion,
    puttingCmsDeportivaReasignacionError:
      comisionesV2.puttingCmsDeportivaReasignacionError
  };
};
