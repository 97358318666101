import { Preview } from "../../../../types/IAction";
import {
  GetCmsProductosAsignados,
  GetCmsClasesAsignadas,
  PutCmsClasesImparticion,
  PutCmsClasesReasignacion
} from "../../../reducers/comisionesV2";
import * as actions from "../../../actions/comisionesV2";

export interface CImparticionDispatcher {
  getCmsProductosAsignados: () => Promise<Preview<GetCmsProductosAsignados>>;
  getCmsClasesAsignadas: (
    params?: any
  ) => Promise<Preview<GetCmsClasesAsignadas>>;
  putCmsClasesImparticion: (
    body: any
  ) => Promise<Preview<PutCmsClasesImparticion>>;
  putCmsClasesReasignacion: (
    body: any
  ) => Promise<Preview<PutCmsClasesReasignacion>>;
}

export const cImparticionDispatcher: (
  dispatch: (...args) => Promise<Preview<any>>
) => CImparticionDispatcher = dispatch => ({
  getCmsProductosAsignados: () => {
    return dispatch(actions.getCmsProductosAsignados());
  },
  getCmsClasesAsignadas: (params?: any) => {
    return dispatch(actions.getCmsClasesAsignadas(params));
  },
  putCmsClasesImparticion: (body: any) => {
    return dispatch(actions.putCmsClasesImparticion(body));
  },
  putCmsClasesReasignacion: (body: any) => {
    return dispatch(actions.putCmsClasesReasignacion(body));
  }
});
