import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import constants from "../../utils/constants";
import { restActionFactory } from "../../toolkit/axiosActions/axiosActionsFactory";
import { deleteEmptyProps } from "../../toolkit/utils/filter-utils";

const COMISIONES_BASE_URL = "/api/comisiones-deportiva";

// Consulta de ventas
export const getCmsProductosVenta = (params?: any): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.GET_COMISIONES_V2_PRODUCTOS_VENTA,
    urlComplement: "/ventas/productos-vendidos",
    method: "get",
    params
  });
};

export const getCmsVentas = (params?: any): IAction => {
  let urlComplement = "";

  if (params && params.tipoBusqueda === "EMPLEADO") {
    urlComplement = "/ventas/empleados";
  } else if (params && params.tipoBusqueda === "FACTURA") {
    urlComplement = "/ventas/facturas";
  } else {
    urlComplement = "/ventas/productos";
  }

  return comisionesRestAction({
    actionType: actionTypes.GET_COMISIONES_V2_VENTAS,
    urlComplement,
    method: "get",
    params: deleteEmptyProps(params)
  });
};

export const putCmsVendedor = (idInstancia: any, body: any): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.PUT_COMISIONES_V2_VENDEDOR,
    urlComplement: `/ventas/${idInstancia}/vendedores`,
    method: "put",
    body
  });
};

export const putCmsInstructorPrincipal = (
  idInstancia: any,
  body: any
): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.PUT_COMISIONES_V2_INSTRUCTOR_PRINCIPAL,
    urlComplement: `/ventas/${idInstancia}/instructores`,
    method: "put",
    body
  });
};

export const putCmsCoordinador = (idInstancia: any, body: any): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.PUT_COMISIONES_V2_COORDINADOR,
    urlComplement: `/ventas/${idInstancia}/coordinadores`,
    method: "put",
    body
  });
};

export const getCmsProductosOperacion = (params?: any): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.GET_COMISIONES_V2_PRODUCTOS_OPERACION,
    urlComplement: "/eventos-asignables/productos-con-eventos",
    method: "get",
    params
  });
};

export const getCmsInstructorComisionable = (params?: any): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.GET_COMISIONES_V2_INSTRUCTOR_COMISIONABLE,
    urlComplement: "/instructor-comisionable",
    method: "get",
    params
  });
};

export const getCmsClasesAsignables = (params?: any): IAction => {
  let urlComplement = "";

  if (params.tipoBusqueda === "EMPLEADO") {
    urlComplement = "/eventos-asignables/empleados";
  } else if (params.tipoBusqueda === "FACTURA") {
    urlComplement = "/eventos-asignables/facturas";
  } else if (params.tipoBusqueda === "CLIENTE") {
    urlComplement = "/eventos-asignables/clientes";
  } else if (params.tipoBusqueda === "MEMBRESIA") {
    urlComplement = "/eventos-asignables/membresias";
  } else {
    urlComplement = "/eventos-asignables/productos";
  }

  return comisionesRestAction({
    actionType: actionTypes.GET_COMISIONES_V2_CLASES_ASIGNABLES,
    urlComplement,
    method: "get",
    params
  });
};

export const postCmsAsignacion = (
  body: any,
  esClasePersonalizada: boolean
): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.POST_COMISIONES_V2_ASIGNACION,
    urlComplement: `/asignacion/${
      esClasePersonalizada ? "clases-personalizadas" : "programas-deportivos"
    }`,
    method: "post",
    body
  });
};

export const getCmsProductosAsignados = (): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.GET_COMISIONES_V2_PRODUCTOS_ASIGNADOS,
    urlComplement: "/eventos-asignados/productos-aplicables",
    method: "get"
  });
};

export const getCmsClasesAsignadas = (params?: any): IAction => {
  let urlComplement = "";

  if (params.tipoBusqueda === "FECHA") {
    urlComplement = "/eventos-asignados/fechas-asignacion";
    params.estatus = params.estatus || "ASIGNADA";
  } else if (params.tipoBusqueda === "PERIODO") {
    urlComplement = "/eventos-asignados/periodos";
    params.estatus = params.estatus || "ASIGNADA";
  } else if (params.tipoBusqueda === "EMPLEADO") {
    urlComplement = "/eventos-asignados/empleados";
    params.estatus = params.estatus || "ASIGNADA";
  } else if (params.tipoBusqueda === "FACTURA") {
    urlComplement = "/eventos-asignados/facturas";
  } else {
    urlComplement = "/eventos-asignados/productos";
    params.estatus = params.estatus || "ASIGNADA";
  }

  return comisionesRestAction({
    actionType: actionTypes.GET_COMISIONES_V2_CLASES_ASIGNADAS,
    urlComplement,
    method: "get",
    params
  });
};

export const putCmsClasesImparticion = (body: any): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.PUT_COMISIONES_V2_EVENTO_IMPARTICION,
    urlComplement: "/eventos-asignados/gestionar",
    method: "put",
    body
  });
};

export const putCmsClasesReasignacion = (body: any): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.PUT_COMISIONES_V2_EVENTO_REASIGNACION,
    urlComplement: "/eventos-asignados/reasignar",
    method: "put",
    body
  });
};

export const getCmsDeportivaPeriodo = (): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.GET_COMISIONES_V2_DEPORTIVA_PERIODO,
    urlComplement: "/autorizacion/periodos-opciones",
    method: "get"
  });
};

export const getCmsDeportivaAutorizacion = (params?: any): IAction => {
  let urlComplement = "";

  if (params.tipoBusqueda === "FACTURA") {
    urlComplement = "/autorizacion/facturas";
  } else {
    urlComplement = "/autorizacion/periodos";
  }

  return comisionesRestAction({
    actionType: actionTypes.GET_COMISIONES_V2_DEPORTIVA_AUTORIZACION,
    urlComplement,
    method: "get",
    params
  });
};

export const putCmsDeportivaAutorizar = (body: any): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.PUT_COMISIONES_V2_DEPORTIVA_AUTORIZAR,
    urlComplement: "/autorizacion/autorizar",
    method: "put",
    body
  });
};

export const putCmsDeportivaRechazar = (id: any): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.PUT_COMISIONES_V2_DEPORTIVA_RECHAZAR,
    urlComplement: "/autorizacion/rechazar",
    method: "put"
  });
};

export const putCmsDeportivaReasignar = (body: any): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.PUT_COMISIONES_V2_DEPORTIVA_REASIGNAR,
    urlComplement: "/autorizacion/reasignar",
    method: "put",
    body
  });
};

const comisionesRestAction = restActionFactory(
  `${constants.BASE_URL}${COMISIONES_BASE_URL}`
);
