import React, { Component } from "react";
import {
  RouterProps,
  Styled
} from "../../../../../toolkit/decorators/Artifact";
import { CImparticionDispatcher } from "../../../../containers/comisionesDeportivaV2Carrusel/steps/CImparticion.dispatcher";
import { CImparticionStore } from "../../../../containers/comisionesDeportivaV2Carrusel/steps/CImparticion.store";
import { Container } from "reactstrap";
import FormBuilder from "../../../../../toolkit/baseForms2/formBuilder/FormBuilder";
import { Inputs, Buttons, formConfig } from "./CImparticion.form";
import { MemoTable } from "./CImparticion.table";
import DeportivaEventoAsignado from "../../../../../types/DeportivaEventoAsignado";
import { deleteEmptyProps } from "../../../../../toolkit/utils/filter-utils";
import {
  errorNotification,
  successNotification
} from "../../../../../utils/notifications";
import s from "../../styles.scss";
import FormBuilderModal from "../../../common/formBuilderModal/formBuilderModal";
import ConfirmModal from "../../../common/confirmModal/confirmModal";
import { formConfigClase } from "./CImparticionClase.form";
import { formConfigPrograma } from "./CImparticionPrograma.form";
import ITypeSelectBuilder from "../../../../../toolkit/baseForms2/iTypeSelectBuilder/ITypeSelectBuilder";
import { formConfigMarcarClase } from "./CImparticionMarcarClase.form";
import { formConfigMarcarPrograma } from "./CImparticionMarcarPrograma.form";

interface Props
  extends CImparticionDispatcher,
    CImparticionStore,
    RouterProps {}

interface State {
  downloading: boolean;
  waiting: boolean;
  filters: Inputs & {
    page: number;
    size: number;
  };
  submitted: boolean;

  // Actualización del estado del evento
  rowPopup: DeportivaEventoAsignado;
  marcaPopupOpenClase: boolean;
  marcaPopupOpenPrograma: boolean;
  marcaPopupTitle: string;
  marcaPopupText: string;
  marcaPopupTipo:
    | "IMPARTIDA"
    | "FALTA_INSTRUCTOR"
    | "FALTA_CLIENTE"
    | "CANCELADA";
  marcaPopupWithForm: boolean;
  clasePopupOpen: boolean;
  clasePopupTitle: string;
  clasePopupText: string;
  programaPopupOpen: boolean;
  programaPopupTitle: string;
  programaPopupText: string;
}

@Styled(s)
export default class CImparticionComponent extends Component<Props, State> {
  state = {
    downloading: false,
    waiting: false,
    filters: {
      page: 0,
      size: 100
    } as any,
    submitted: false,

    // Actualización de empleado
    rowPopup: null as DeportivaEventoAsignado,
    marcaPopupOpenClase: false,
    marcaPopupOpenPrograma: false,
    marcaPopupTitle: "",
    marcaPopupText: "",
    marcaPopupTipo: null as
      | "IMPARTIDA"
      | "FALTA_INSTRUCTOR"
      | "FALTA_CLIENTE"
      | "CANCELADA",
    marcaPopupWithForm: false,
    clasePopupOpen: false,
    clasePopupTitle: "",
    clasePopupText: "",
    programaPopupOpen: false,
    programaPopupTitle: "",
    programaPopupText: ""
  };

  componentDidMount(): void {
    this.props.getCmsProductosAsignados();
  }

  submit = (values: any) => {
    if (values.tipoBusqueda === "PERIODO") {
      values.periodo = `${values.anio}-${values.mes}-01`;
    }

    // Búsqueda de datos en página 1
    this.setState({ submitted: true });
    this.fetchData({ page: 0 } as any, null, values);
  };

  // Carga de datos para poblar la tabla (React Table usa el segundo argumento)
  fetchData = async (
    { pageSize: tablePageSize, page: tablePage },
    _?: any,
    formFilters?
  ) => {
    // No debe existir una búsqueda por defecto
    if (!this.state.submitted && !formFilters) {
      return;
    }

    const filters = formFilters || this.state.filters;
    const page = tablePage >= 0 ? tablePage : this.state.filters.page;
    const size = tablePageSize >= 0 ? tablePageSize : this.state.filters.size;
    const pageableFilters = deleteEmptyProps({ ...filters, page, size });

    this.setState({ filters: pageableFilters });

    const preview = await this.props.getCmsClasesAsignadas(pageableFilters);
    if (!preview.fulfilled) {
      errorNotification(
        preview.gettingCmsClasesAsignadasError ||
          "Error al obtener las sesiones asignadas"
      );
    }
  };

  onExport = () => {
    // TODO: Verificar si se necesita
    // this.setState({ downloading: true });
  };

  onMarcarImpartido = (row: DeportivaEventoAsignado) => {
    this.setState({
      rowPopup: row,
      marcaPopupOpenClase: !!row.idClase,
      marcaPopupOpenPrograma: !row.idClase,
      marcaPopupTitle: "Marcar como impartida",
      marcaPopupText:
        "Confirme la impartición de la clase o participación en el programa deportivo",
      marcaPopupTipo: "IMPARTIDA",
      marcaPopupWithForm: true
    });
  };

  onMarcarFaltaInstructor = (row: DeportivaEventoAsignado) => {
    this.setState({
      rowPopup: row,
      marcaPopupOpenClase: !!row.idClase,
      marcaPopupOpenPrograma: !row.idClase,
      marcaPopupTitle: "Marcar falta de instructor",
      marcaPopupText:
        "Confirme que faltó el INSTRUCTOR, esta operación no se puede deshacer",
      marcaPopupTipo: "FALTA_INSTRUCTOR",
      marcaPopupWithForm: false
    });
  };

  onMarcarFaltaCliente = (row: DeportivaEventoAsignado) => {
    this.setState({
      rowPopup: row,
      marcaPopupOpenClase: !!row.idClase,
      marcaPopupOpenPrograma: !row.idClase,
      marcaPopupTitle: "Marcar falta de cliente",
      marcaPopupText:
        "Confirme que faltó el CLIENTE, esta operación no se puede deshacer",
      marcaPopupTipo: "FALTA_CLIENTE",
      marcaPopupWithForm: true
    });
  };

  onActualizar = (row: DeportivaEventoAsignado) => {
    if (row.idClase) {
      this.setState({
        rowPopup: row,
        clasePopupOpen: true,
        clasePopupTitle: "Reprogramar clase",
        clasePopupText: "Ingrese la nueva información de la clase"
      });
    } else {
      this.setState({
        rowPopup: row,
        programaPopupOpen: true,
        programaPopupTitle: "Reprogramar participación",
        programaPopupText:
          "Ingrese la nueva información de la participación en el programa"
      });
    }
  };

  onCancelar = (row: DeportivaEventoAsignado) => {
    this.setState({
      rowPopup: row,
      marcaPopupOpenClase: !!row.idClase,
      marcaPopupOpenPrograma: !row.idClase,
      marcaPopupTitle: "Cancelar",
      marcaPopupText:
        "¿Está seguro de que debe cancelar " +
        (!!row.idClase
          ? "la clase personalizada"
          : "la participación en el programa") +
        "?",
      marcaPopupTipo: "CANCELADA",
      marcaPopupWithForm: false
    });
  };

  marcar = async (value?) => {
    const { rowPopup: row, marcaPopupTipo } = this.state;
    let preview;

    if (marcaPopupTipo === "IMPARTIDA") {
      preview = await this.props.putCmsClasesImparticion({
        ...row,
        ...value,
        estatus: "IMPARTIDA"
      });
    } else if (marcaPopupTipo === "FALTA_INSTRUCTOR") {
      preview = await this.props.putCmsClasesImparticion({
        ...row,
        estatus: "FALTA_INSTRUCTOR"
      });
    } else if (marcaPopupTipo === "FALTA_CLIENTE") {
      preview = await this.props.putCmsClasesImparticion({
        ...row,
        ...value,
        estatus: "FALTA_CLIENTE"
      });
    } else if (marcaPopupTipo === "CANCELADA") {
      preview = await this.props.putCmsClasesImparticion({
        ...row,
        estatus: "CANCELADA"
      });
    }

    if (!preview.fulfilled) {
      errorNotification(
        preview.puttingCmsClasesImparticionError ||
          "Error al actualizar la clase o programa deportivo"
      );
    } else {
      this.setState({
        marcaPopupOpenClase: false,
        marcaPopupOpenPrograma: false
      });
      this.fetchData({} as any);
    }
  };

  reasignarClase = async values => {
    const { rowPopup: row } = this.state;
    const body = {
      idProductoInstancia: row.idProductoInstancia,
      idClase: row.idClase,
      idInstructor: values.idInstructor,
      fechaClase: values.fechaClase,
      horaClase: values.horaClase
    };

    const preview = await this.props.putCmsClasesReasignacion(body);

    if (!preview.fulfilled) {
      errorNotification(
        preview.puttingCmsClasesReasignacionError ||
          "Error al reasignar la clase"
      );
    } else {
      successNotification("Clase reasignada correctamente");
      this.setState({
        clasePopupOpen: false
      });
      this.fetchData({} as any);
    }
  };

  reasignarPrograma = async values => {
    const { rowPopup: row } = this.state;
    const body = {
      idProductoInstancia: row.idProductoInstancia,
      idParticipacion: row.idParticipacion,
      idInstructor: values.idInstructor,
      periodo: values.anio + "-" + values.mes + "-01"
    };

    const preview = await this.props.putCmsClasesReasignacion(body);

    if (!preview.fulfilled) {
      errorNotification(
        preview.puttingCmsClasesReasignacionError ||
          "Error al reasignar la participación en el programa"
      );
    } else {
      successNotification("Participación en programa reasignada correctamente");
      this.setState({
        programaPopupOpen: false
      });
      this.fetchData({} as any);
    }
  };

  render() {
    return (
      <Container>
        <FormBuilderModal
          isOpen={
            this.state.marcaPopupOpenClase && this.state.marcaPopupWithForm
          }
          title={this.state.marcaPopupTitle}
          message={this.state.marcaPopupText}
          ok={this.marcar}
          cancel={() => this.setState({ marcaPopupOpenClase: false })}
          formConfig={formConfigMarcarClase}
          size={"md"}
        />
        <FormBuilderModal
          isOpen={
            this.state.marcaPopupOpenPrograma && this.state.marcaPopupWithForm
          }
          title={this.state.marcaPopupTitle}
          message={this.state.marcaPopupText}
          ok={this.marcar}
          cancel={() => this.setState({ marcaPopupOpenPrograma: false })}
          formConfig={formConfigMarcarPrograma}
          size={"md"}
        />
        <FormBuilderModal
          isOpen={this.state.clasePopupOpen}
          title={this.state.clasePopupTitle}
          message={this.state.clasePopupText}
          ok={this.reasignarClase}
          cancel={() => this.setState({ clasePopupOpen: false })}
          formConfig={formConfigClase}
          size={"md"}
        />
        <FormBuilderModal
          isOpen={this.state.programaPopupOpen}
          title={this.state.programaPopupTitle}
          message={this.state.programaPopupText}
          ok={this.reasignarPrograma}
          cancel={() => this.setState({ programaPopupOpen: false })}
          formConfig={formConfigPrograma}
          size={"md"}
        />
        <ConfirmModal
          isOpen={
            (this.state.marcaPopupOpenClase ||
              this.state.marcaPopupOpenPrograma) &&
            !this.state.marcaPopupWithForm
          }
          title={this.state.marcaPopupTitle}
          message={this.state.marcaPopupText}
          ok={this.marcar}
          cancel={() =>
            this.setState({
              marcaPopupOpenClase: false,
              marcaPopupOpenPrograma: false
            })
          }
          size={"md"}
          waiting={this.props.puttingCmsClasesImparticion}
        />

        <FormBuilder<Inputs, Buttons>
          config={formConfig}
          submit={this.submit}
          processing={this.props.gettingCmsClasesAsignadas}
        >
          {/* Selector de productos */}
          <ITypeSelectBuilder<Inputs>
            name="idProducto"
            data={this.props.cmsProductosAsignados || []}
            mapOption={option => ({
              value: option.idProducto,
              label: option.producto
            })}
            isLoading={this.props.gettingCmsProductosAsignados}
          />
        </FormBuilder>

        <hr className="row" />

        <MemoTable
          page={
            this.props.cmsClasesAsignadas ||
            ({} as Page<DeportivaEventoAsignado>)
          }
          loading={
            this.state.downloading || this.props.gettingCmsClasesAsignadas
          }
          onFetchData={this.fetchData}
          onExport={this.onExport}
          onMarcarImpartido={this.onMarcarImpartido}
          onMarcarFaltaInstructor={this.onMarcarFaltaInstructor}
          onMarcarFaltaCliente={this.onMarcarFaltaCliente}
          onActualizar={this.onActualizar}
          onCancelar={this.onCancelar}
        />
      </Container>
    );
  }
}
