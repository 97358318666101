import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import moment from "moment";
import {
  Container,
  Col,
  Button,
  Row,
  UncontrolledTooltip,
  Form
} from "reactstrap";
import {
  faEdit,
  faPlusCircle,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BaseInput from "../../../toolkit/baseInput";
import s from "./styles.scss";
import {
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import {
  getAnnuitiesRequest,
  cancelAnnuitiesByIds
} from "../../actions/annuities";
import { handleRequestError } from "../../../utils/helpers";
import { userHasPermission } from "../../../utils/accessTree";
import permissionCodes from "../../../utils/permissionCodes";

//Tipos
const RESTO_ANIO = "RESTO_ANIO";
const MESES_ROLADOS = "MESES_ROLADOS";
const ANUAL = "ANUAL";
//Estatus
const CREADA = "CREADA";
const AUTORIZADA = "AUTORIZADA";
const RECHAZADA = "RECHAZADA";
const CANCELADA = "CANCELADA";

interface Props {
  history: any;
}

interface State {
  filters: object;
  data: any[];
  onSearch: boolean;
  isOpen: boolean;
}

class Annuities extends React.Component<Props, State> {
  state = {
    data: [],
    filters: {
      tipo: null,
      anio: null,
      estatus: null,
      nombre: null
    },
    isOpen: false,
    onSearch: false
  };

  columns = [
    {
      Header: () => <p className="font-weight-bold">Tipo</p>,
      id: 0,
      className: "text-center",
      accessor: "tipo"
    },
    {
      Header: () => <p className="font-weight-bold">Año</p>,
      id: 0,
      className: "text-center",
      accessor: "anio"
    },
    {
      Header: () => <p className="font-weight-bold">Nombre</p>,
      id: 0,
      width: 250,
      accessor: "nombre"
    },
    {
      Header: () => <p className="font-weight-bold">Estatus</p>,
      id: 0,
      className: "text-center",
      accessor: "estatus"
    },
    {
      Header: () => <p className="font-weight-bold">Meses aplicables</p>,
      id: 0,
      className: "text-center",
      accessor: "mesesAplicables"
    },
    {
      Header: () => (
        <Container className="w-100 text-center">
          <Button
            color="link"
            onClick={e => this.props.history.push(`/anualidades/editar`)}
            className={`${s.innerButton} ${s.primaryLink}`}
          >
            <FontAwesomeIcon icon={faPlusCircle} />
          </Button>
        </Container>
      ),
      id: -300,
      width: 120,
      accessor: "actions",
      Cell: row => {
        return (
          <Container className="p-0">
            <Row className="m-0">
              {userHasPermission(permissionCodes.ANNUITIES_EDIT_CONFIG) && (
                <Col className="px-1 text-center">
                  <a
                    id={`anualidad-${row.original.id}`}
                    onClick={e => {
                      window.open(
                        `/anualidades/editar/${row.original.id}`,
                        "_blank"
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      icon={faEdit}
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={`anualidad-${row.original.id}`}
                    >
                      Editar
                    </UncontrolledTooltip>
                  </a>
                </Col>
              )}
              {row.original.estatus != CANCELADA ? (
                <Col className="px-1 text-center">
                  <a
                    id={`anualidad-${row.original.id}-cancelar`}
                    onClick={this.cancelAnnuity(row.original.id)}
                  >
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      icon={faTimesCircle}
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={`anualidad-${row.original.id}-cancelar`}
                    >
                      Cancelar
                    </UncontrolledTooltip>
                  </a>
                </Col>
              ) : (
                <Col className="px-1 text-center" />
              )}
            </Row>
          </Container>
        );
      }
    }
  ];

  componentDidMount() {
    this.search();
  }

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value ? value : null
        };
        return { ...state, filters };
      });
    };
  };

  cancelAnnuity = id => {
    return event => {
      event.preventDefault();

      cancelAnnuitiesByIds([id])
        .then(() => {
          this.setState({ data: [] });
          this.search();
        })
        .catch(handleRequestError);
    };
  };

  search = () => {
    this.setState({
      onSearch: true
    });

    infoNotification("Consultando datos...");

    getAnnuitiesRequest({ ...this.state.filters })
      .then(({ data }) => {
        this.setState({ data, onSearch: false });
        successNotification("Datos consultados correctamente...");
      })
      .catch(handleRequestError);
  };

  render() {
    const { data } = this.state;

    return (
      <Container className="p-0">
        <Form
          onSubmit={e => {
            e.preventDefault();
            this.search();
          }}
        >
          <Row>
            <Col xs={2} className="pr-0 pt-2">
              <BaseInput
                label={"Tipo"}
                name="anualidadTipo"
                id="anualidadTipo"
                type="select"
                value={this.state.filters.tipo}
                options={[
                  { value: "", label: "Seleccione" },
                  { value: ANUAL, label: "ANUAL" },
                  { value: MESES_ROLADOS, label: "MESES ROLADOS" },
                  { value: RESTO_ANIO, label: "RESTO AÑO" }
                ]}
                onChange={this.onChangeFilter("tipo")}
              />
            </Col>
            <Col xs={2} className="pr-0 pt-2">
              <BaseInput
                label={"Año"}
                name="anualidadAnio"
                id="anualidadAnio"
                type="select"
                value={this.state.filters.anio}
                options={[
                  { value: "", label: "Seleccione" },
                  { value: moment().year() - 2, label: moment().year() - 2 },
                  { value: moment().year() - 1, label: moment().year() - 1 },
                  { value: moment().year(), label: moment().year() },
                  { value: moment().year() + 1, label: moment().year() + 1 },
                  { value: moment().year() + 2, label: moment().year() + 2 },
                  { value: moment().year() + 3, label: moment().year() + 3 },
                  { value: moment().year() + 4, label: moment().year() + 4 }
                ]}
                onChange={this.onChangeFilter("anio")}
              />
            </Col>
            <Col xs={2} className="pr-0 pt-2">
              <BaseInput
                label={"Estatus"}
                name="anualidadEstatus"
                id="anualidadEstatus"
                type="select"
                value={this.state.filters.estatus}
                options={[
                  { value: "", label: "Seleccione" },
                  { value: CREADA, label: CREADA },
                  { value: AUTORIZADA, label: AUTORIZADA },
                  { value: RECHAZADA, label: RECHAZADA },
                  { value: CANCELADA, label: CANCELADA }
                ]}
                onChange={this.onChangeFilter("estatus")}
              />
            </Col>
            <Col xs={4} className="pr-0 pt-2">
              <BaseInput
                label={"Nombre:"}
                name="anualidadNombre"
                id="anualidadNombre"
                type="text"
                placeholder="Nombre"
                value={this.state.filters.nombre}
                onChange={this.onChangeFilter("nombre")}
              />
            </Col>
            <Col>
              <br />
              <Button
                size="sm"
                className={`float-right btn-block mt-2`}
                type="submit"
              >
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>
        <ReactTable
          data={data || []}
          columns={this.columns}
          className="mt-5 -striped -highlight"
          sortable={false}
          resizable={false}
          pageSize={100}
        />
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(Annuities));
